import React, { useEffect, useRef, useState } from 'react'
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Pagination,
    Box,
    Grid,
    CircularProgress,
    Divider,
    Modal,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    OutlinedInput,
    Chip,
    Switch,


} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearError, updateCourse, getAllCourse, getAvailCategory } from '../../redux/actions/courseAction';
import { CREATE_COURSE_RESET, UPDATE_COURSE_RESET } from '../../redux/constants/courseConstant';
import TextEditor from '../../components/TextEditor';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    // height: '90vh',
    overFlow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const UpdateCourseUi = ({ course, updateModalOpen, setUpdateModalOpen }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //  -------------------  update modal code  -----------------------------------
    const { loading, success, error } = useSelector((state) => state.actionCourse)
    const { user } = useSelector((state) => state.users)
    const { loading: catLoading, categories, error: catErr } = useSelector((state) => state.getCategories)

    useEffect(() => {
        if (catErr) {
            toast.error(catErr);
            dispatch(clearError());
        }
        dispatch(getAvailCategory())
    }, [])

    useEffect(() => {
        if (success) {
            toast.success('Updated success');
            updateModalHandleClose()
            dispatch({ type: UPDATE_COURSE_RESET });
            setCategory([])
            dispatch(getAllCourse())
        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
    }, [error, success, dispatch])

    //  const [updateItem, setUpdateItem] = useState();
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [html, setHtml] = useState('');
    const htmlRef = useRef('');

    const [isPaid, setIsPaid] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [file, setFile] = useState('');
    const [discount, setDiscount] = useState('');
    const [price, setPrice] = useState('');
    const [expired, setExpired] = useState(0);
    const [category, setCategory] = useState([]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            //   if (
            //     file.type === "application/vnd.ms-excel" ||
            //     file.type ===
            //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            //   ) {
            if (file.size <= 2 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setFile(e.target.result);
                    // console.log(selectedFile)
                }
            } else {
                event.target.value = null;
                setFile(null);
                alert("File size exceeds the limit of 5 MB.");
            }
        } else {
            event.target.value = null;
            setFile(null);
            alert("Please select an  file.");
        }
        // }
    };





    //  const [updateModalOpen, setUpdateModalOpen] = useState(false);

    const updateModalHandleClose = () => {
        setUpdateModalOpen(false);
    }

    const updateSubmitHandler = (e) => {
        e.preventDefault()
        const categories = [];

        category.forEach(item => {
            categories.push(item._id)
        })
        //  console.log(categories)
        if (!isPaid) {
            setPrice(0)
            setDiscount(0)
        }


            if (isPaid && price < 1) {
                toast.error("Price should greater then 0");
            }
            else if (isPaid && discount < 1 || discount > 99) {
                toast.error("Discount should greater then 0 and less then 100");
            } else if (expired < 1) {
                toast.error("Expired should greater then 0");
            }
        else {
            const details = { name, title, expired, isPaid, file, isActive,discount:!isPaid ? 0 : discount, price:!isPaid ? 0 : price, description: htmlRef.current, created_by: user?.name, categories }
            dispatch(updateCourse(details, course?._id))
        }
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setCategory(
            // On autofill we get a stringified value.
            value
        );
    };


    useEffect(() => {
        console.log(course)
        setName(course?.name)
        setTitle(course?.title)
        setDiscount(course?.discount)
        setPrice(course?.price)
        // setFile(course?.name)
        // setCategory(

        // )
        setIsActive(course?.isActive)
        setIsPaid(course?.isPaid)
        setHtml(course?.description)
        setExpired(course?.expired)
        htmlRef.current = course?.description



    }, [course])

    return (
        <div>
            <Modal
                open={updateModalOpen}
                onClose={updateModalHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="courseScroll">
                    <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            Update Course
                        </Typography>
                        <Button onClick={updateModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                    </Stack>

                    <Divider />

                    <form action="" onSubmit={updateSubmitHandler}>
                        <Stack spacing={3} margin='1vmax 0'>
                            <TextField name="name"
                                size='small'
                                value={name}
                                onChange={e => setName(e.target.value)}
                                required label="Course Name" />
                        </Stack>
                        <Stack spacing={3} margin='1vmax 0'>
                            <TextField
                                size='small'
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                required label="Heading/  Title" />
                        </Stack>

                        <FormControl sx={{ width: '100%' }}>

                            <Stack spacing={3} margin='1vmax 0'>

                                <TextField
                                    size='small'
                                    value={expired}
                                    onChange={e => setExpired(e.target.value)}
                                    required label="Expired" />
                            </Stack>
                        </FormControl>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="demo-multiple-chip-label">Replace with new Categories</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={category}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Replace with new Categories" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value.categoryName} />
                                        ))}
                                    </Box>
                                )}
                            // MenuProps={MenuProps}
                            >
                                {categories?.map((item) => (
                                    <MenuItem
                                        key={name}
                                        value={item}
                                    // style={getStyles(name, personName, theme)}
                                    >
                                        {item.categoryName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Stack spacing={3} margin='0.5vmax 0.5vmax' direction={'row'} alignItems={'center'}>
                            <Typography id="modal-modal-title" variant="p" component="h4">Is Paid</Typography>
                            <Switch
                                checked={isPaid}
                                onChange={e => setIsPaid(!isPaid)}
                            />
                        </Stack>
                        {
                            isPaid && <>
                                <Stack spacing={3} margin='1vmax 0'>
                                    <TextField
                                        size='small'
                                        value={price}
                                        onChange={e => setPrice(e.target.value)}
                                        required label="Price" />
                                </Stack>
                                <Stack spacing={3} margin='1vmax 0'>
                                    <TextField
                                        size='small'
                                        value={discount}
                                        onChange={e => setDiscount(e.target.value)}
                                        required label="Discount" />
                                </Stack>
                                <Typography variant="p" color="initial">
                                    {
                                        (price < 1 || discount < 1 || discount > 99) ? <p style={{ color: 'red' }}>Price or Discount should greater then 0. and discount should less then 100.</p> :
                                            <>Price after discount : {price - (price * discount / 100)}</>
                                    }
                                </Typography>
                            </>
                        }
                        <Stack spacing={3} margin='0.5vmax 0.5vmax' direction={'row'} alignItems={'center'}>
                            <Typography id="modal-modal-title" variant="p" component="h4">Is Active</Typography>
                            <Switch
                                checked={isActive}
                                onChange={e => setIsActive(!isActive)}
                            />
                        </Stack>

                        <Stack spacing={3} margin='1vmax 0' direction={'row'} padding={3} borderRadius={2} className='bg' justifyContent={'space-between'} alignItems={'center'}>
                            <Stack spacing={1} margin='1vmax 0' >
                                <input
                                    className="intFileBtn"
                                    // required
                                    type="file"
                                    accept=".png , .jpg,"
                                    onChange={handleFileChange}
                                    name="file"
                                    placeholder="k"
                                />
                                <p className="fileName">Select Only .jpg, .png</p>
                            </Stack>
                            {
                                file && <img src={file} alt="" height={200} />
                            }
                        </Stack>

                        <TextEditor content={html} setContent={setHtml} htmlRef={htmlRef} />

                        <br />

                        {loading ?

                            (<LoadingButton fullWidth size="large" variant="text" >
                                <CircularProgress color='success' />
                            </LoadingButton>)
                            :

                            (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                UPDATE
                            </LoadingButton>)

                        }
                    </form>
                </Box>
            </Modal>
        </div>

    )
}

export default UpdateCourseUi
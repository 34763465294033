// import React, { useState, useRef, useMemo } from 'react';
// import JoditEditor from 'jodit-react';
import './editor.css'
// // ----------------------------------------------------------------------

// const TextEditor = ({ content, setContent }) => {
// 	const editor = useRef(null);
// 	const value = 9999

// 	return (
// 		<div>

// 			<JoditEditor
// 				ref={editor}
// 				// sx={style}
// 				className='editor'
// 				value={content}
// 				config={{
// 					readonly: false,
// 					toolbar: true,
// 					uploader: {
// 					  insertImageAsBase64URI: true,
// 					  imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
// 					},

// 				  }}
// 				tabIndex={value} // tabIndex of textarea
// 				// onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
// 				onChange={newContent => setContent(newContent)}
// 			/>
// 		</div>

// 	);
// };

// export default TextEditor


// ---------------------- tinymce ---------------------
// src/FroalaEditorComponent.js
// import React, { useState } from 'react';
// import FroalaEditor from 'react-froala-wysiwyg';

// import 'froala-editor/js/froala_editor.pkgd.min';
// import 'froala-editor/css/froala_editor.pkgd.min.css';

// const TextEditor = () => {
//   const [content, setContent] = useState('');

//   const handleModelChange = (model) => {
//     setContent(model);
//   };
//   const config = {
//     placeholderText: 'Edit Your Content Here!',
//     charCounterCount: true,
//     toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'paragraphFormat', 'alert'],
//   };

//   return (
//     <div>
//       <h2>Froala Editor</h2>
//       <FroalaEditor
//         tag="textarea"
//         model={content}
//         onModelChange={handleModelChange}
// 		// config={config}
//       />
//     </div>
//   );
// };

// export default TextEditor;


// src/JoditEditorComponent.js


// src/components/ReactDraftWysiwygEditor.js


// no 3

// import React, { useState } from 'react';
// import { EditorState } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// const ReactDraftWysiwygEditor = () => {
//   const [editorState, setEditorState] = useState(EditorState.createEmpty());

//   const handleEditorChange = (state) => {
// 	//   const editState = state.getCurrentContent()
// 	//   const contentState = editState.getCurrentContent();
// 	//   setEditorState(contentState.getPlainText());
// 	//   console.log(contentState.getPlainText())
// 	// console.log(editState.getCurrentContent)
// 	setEditorState(state)
//     // return ;
//   };
//   const getPlainText = () => {
//     const contentState = editorState.getCurrentContent();
// 	console.log(contentState)
//     // return contentState;
//   };

//   return (
//     <div className="editor-container">
//       <h2>React Draft Wysiwyg Editor</h2>
//       <Editor
//         // editorState={editorState}
//         onEditorStateChange={handleEditorChange}
//         toolbar={{
//           options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'history'],
//           inline: { options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'] },
//           blockType: { className: 'block-type-dropdown' },
//           list: { options: ['unordered', 'ordered'] },
//           textAlign: { options: ['left', 'center', 'right', 'justify'] },
//           link: { options: ['link'] },
//         }}
//       />
// 	  {getPlainText()}
// 	  {/* <div dangerouslySetInnerHTML={{ __html: contentStateFromRaw ? contentStateFromRaw.getPlainText() : '' }} /> */}

//     </div>
//   );
// };

// export default ReactDraftWysiwygEditor;

// no 4
import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import JoditEditor from 'jodit-react';

const TextEditor = ({ content, setContent ,htmlRef}) => {
	const editor = useRef(null);
	// const [content, setContent] = useState('');
	// let content;

	const config =
	{
		readonly: false, // all options from https://xdsoft.net/jodit/docs/,
		placeholder: 'Start typings...',
		uploader: {
			insertImageAsBase64URI: true, // Enable direct image upload
			url: '/upload', // Your upload endpoint
		},
		buttons: [
			'bold', 'italic', 'underline', 'strikethrough', 'eraser', 'ul', 'ol', 'outdent', 'indent', 'font', 'fontsize', 'brush', 'paragraph', 'image', 'video', 'table', 'link', 'align', 'undo', 'redo', 'hr', 'copyformat', 'fullsize', 'preview', 'print', 'about'
		],
		
	}
	const handleBlur = useCallback((newContent) => {
		setContent(newContent);
	}, []);


	
	
	const handleContent = (cont) => {
		// console.log(content)
		
		content = cont;
		htmlRef.current = cont;
		
	}



	const tab = 1
	return (
		<JoditEditor
			ref={editor}
			value={content}
			config={config}
			onBlur={handleBlur} // preferred to use only this option to update the content for performance reasons
			onChange={content => handleContent(content)}
			tabIndex={tab} // tabIndex of textarea
			className='focus'

		/>
	);
};

export default TextEditor;
import axios from 'axios'
import { host } from './config'

const buyCourseHandle = async (user, course,total) => {

    const url = `${host}/payment/api/v1/checkout`
    const amount = total

    // console.log(amount)

    try {
        const { data: { key } } = await axios.get(`${host}/payment/api/v1/getKey`)
        console.log('ok paid')

        const { data: { order } } = await axios.post(url, { amount, email: user.email, name: user.name, phone:user.phone,courseId:course._id, userId:user._id  })

        const options = {
            key, // Enter the Key ID generated from the Dashboard
            "amount": order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "Edu Tech", //  your business name
            "description": "Test Transaction",
            "image": "http://localhost:3000/static/media/ssp_logo_high.7a235541eb9ebe0462b7.png",
            "order_id": order.id, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "callback_url": `${host}/payment/api/v1/paymentVerification`,
            "prefill": { // We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
                "name": user.name, // your customer's name
                "email": user.email,
                "contact": user.phone // Provide the customer's phone number for better conversion rates 
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#ff9900"
            }
        };
        const razor = new window.Razorpay(options);
        razor.open();
    } catch (error) {
        console.log(error)
        alert(error)

    }
}

export default buyCourseHandle
import axios from "axios";
import {
    CLEAR_ERROR,

    CREATE_CHAPTER_REQUEST,
    CREATE_CHAPTER_SUCCESS,
    CREATE_CHAPTER_FAIL,
    GET_ALL_CHAPTER_REQUEST,
    GET_ALL_CHAPTER_SUCCESS,
    GET_ALL_CHAPTER_FAIL,
    GET_CHAPTER_REQUEST,
    GET_CHAPTER_SUCCESS,
    GET_CHAPTER_FAIL,

    
    UPDATE_CHAPTER_REQUEST,
    UPDATE_CHAPTER_SUCCESS,
    UPDATE_CHAPTER_FAIL,
    UPLOAD_FILES_REQUEST,
    UPLOAD_FILES_SUCCESS,
    UPLOAD_FILES_FAIL,
    TRACK_FILE_REQUEST,
    TRACK_FILE_FAIL,
    GET_CHAPTER_FILES_REQUEST,
    GET_CHAPTER_FILES_SUCCESS,
    GET_CHAPTER_FILES_FAIL,
    GET_FILE_REQUEST,
    GET_FILE_FAIL,
    GET_FILE_SUCCESS,
    TRACK_FILE_SUCCESS,
    GET_PAID_CHAPTER_FILES_REQUEST,
    GET_PAID_CHAPTER_FILES_SUCCESS,
    GET_PAID_CHAPTER_FILES_FAIL,
    GET_FILES_DETAILS_REQUEST,
    GET_FILES_DETAILS_SUCCESS,
    GET_FILES_DETAILS_FAIL,
    UPDATE_FILE_REQUEST,
    UPDATE_FILE_FAIL,
    UPDATE_FILE_SUCCESS,
    GET_FILES_ADMIN_REQUEST,
    GET_FILES_ADMIN_SUCCESS,
    GET_FILES_ADMIN_FAIL,

} from '../constants/filesConstant'

import {host} from "../../utils/config";


export const createChapter = (details) => async (dispatch) => {

    try {
        // console.log("create member")

        dispatch({ type: CREATE_CHAPTER_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.post(`${host}/chapter/api/v1/chapter`, details, config);

        dispatch({ type: CREATE_CHAPTER_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: CREATE_CHAPTER_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getAllChapters = (chapterId) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_CHAPTER_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/chapter/api/v1/all-chapters/${chapterId}`, config);

        dispatch({ type: GET_ALL_CHAPTER_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_ALL_CHAPTER_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getChapters = (chapterId) => async (dispatch) => {
    try {
        dispatch({ type: GET_CHAPTER_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/chapter/api/v1/chapters/${chapterId}`, config);

        dispatch({ type: GET_CHAPTER_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_CHAPTER_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getFilesForAdmin = (chapterId) => async (dispatch) => {
    try {
        dispatch({ type: GET_FILES_ADMIN_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/chapter/api/v1/admin/allFile/${chapterId}`, config);

        dispatch({ type: GET_FILES_ADMIN_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_FILES_ADMIN_FAIL,
            payload: error?.response?.data.message,
        });
    }
};


export const updateChapter= (details,chapterId) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_CHAPTER_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.put(`${host}/chapter/api/v1/chapter/${chapterId}`, details, config);

        dispatch({ type: UPDATE_CHAPTER_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: UPDATE_CHAPTER_FAIL,
            payload: error?.response?.data.message,
        });
    }
};
// files

export const uploadVideo = (details) => async (dispatch) => {

    try {
        // console.log("create member")

        dispatch({ type: UPLOAD_FILES_REQUEST });
        const config = { headers: { "Content-Type": "multipart/form-data" }, withCredentials: true };

        const data = await axios.post(`${host}/chapter/api/v1/upload-file`, details, config);

        dispatch({ type: UPLOAD_FILES_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: UPLOAD_FILES_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const fileTrack = (fileId) => async (dispatch) => {
    try {
        dispatch({ type: TRACK_FILE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/chapter/api/v1/file-track/${fileId}`, config);

        dispatch({ type: TRACK_FILE_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: TRACK_FILE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getAllChaptersFile = (chapterId) => async (dispatch) => {
    // console.log(chapterId)
    try {
        dispatch({ type: GET_CHAPTER_FILES_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/chapter/api/v1/allFile/${chapterId}`, config);

        dispatch({ type: GET_CHAPTER_FILES_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_CHAPTER_FILES_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getAllPaidChaptersFile = (chapterId,courseId) => async (dispatch) => {
    // console.log(chapterId)
    try {
        dispatch({ type: GET_PAID_CHAPTER_FILES_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/chapter/api/v1/paid/allFile/${chapterId}?courseId=${courseId}`, config);

        dispatch({ type: GET_PAID_CHAPTER_FILES_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_PAID_CHAPTER_FILES_FAIL,
            payload: error?.response?.data.message,
        });
    }
};


export const getFile = (fileId) => async (dispatch) => {
    try {
        dispatch({ type: GET_FILE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/chapter/api/v1/filter/file/${fileId}`, config);

        dispatch({ type:  GET_FILE_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type:  GET_FILE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};


export const getAllFilesDetails = (courseId) => async (dispatch) => {
    try {
        dispatch({ type: GET_FILES_DETAILS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const {data} = await axios.get(`${host}/chapter/api/v1/files/${courseId}`, config);

        dispatch({ type:  GET_FILES_DETAILS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type:  GET_FILES_DETAILS_FAIL,
            payload: error?.response?.data.message,
        });
    }
};


export const updateFile = (details,fileId) => async (dispatch) => {

    try {
        dispatch({ type: UPDATE_FILE_REQUEST });
        const config = { headers: { "Content-Type": "multipart/form-data" }, withCredentials: true };

        const {data} = await axios.put(`${host}/chapter/api/v1/file/${fileId}`, details,  config);

        dispatch({ type:   UPDATE_FILE_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type:   UPDATE_FILE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};







export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};



export const GET_ALL_PAYMENTS_REQUEST = "GET_ALL_PAYMENTS_REQUEST"
export const GET_ALL_PAYMENTS_SUCCESS = "GET_ALL_PAYMENTS_SUCCESS"
export const GET_ALL_PAYMENTS_FAIL = "GET_GET_ALL_PAYMENTS_FAIL"

export const GET_PAYMENT_REQUEST = "GET_PAYMENT_REQUEST"
export const GET_PAYMENT_SUCCESS = "GET_PAYMENT_SUCCESS"
export const GET_PAYMENT_FAIL = "GET_PAYMENT_FAIL"



export const CLEAR_ERROR = "CLEAR_ERROR"
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, CircularProgress, Box, Button, Typography, FormControl, FormLabel, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';


import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
// components
import { clearError, login, otpVerification } from '../../redux/actions/userAction';
// import { legacy_createStore } from 'redux';


export default function OtpVerify({ setUser }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [otp, setOtp] = useState(null)


    const { loading, user, error } = useSelector((state) => state.email)
    const { loading: meLoading, user: me, isAuthenticated, error: meErr } = useSelector((state) => state.users)




    useEffect(() => {

        if (meErr) {
            toast.error(meErr)
            dispatch(clearError())
        }
        setUser(me)
        if (isAuthenticated) {
            navigate('/dashboard/app')
        }


    }, [error, navigate, dispatch, isAuthenticated, me])

    const loginSubmitHandler = (e) => {
        e.preventDefault()
        dispatch(otpVerification({ email: user?.email, otp }))
    }




    const [count, setCount] = useState(5)

    useEffect(() => {
        if (count < 1) {
            console.log('ooo')
            return undefined;
        }

        const timerId = setInterval(() => {
            setCount(prevCount => prevCount - 1);
        }, 1000);

        return () => clearInterval(timerId)
    });


    //   useEffect(() => {
    //     if (count < 1) {
    //       setCount(0);
    //       clearInterval();
    //     }
    //   }, [count]);


    console.log('x')


    const reSendOtpHandle = () => {

        dispatch(login(user?.email))
    }

    return (
        <>
            <form action="" onSubmit={loginSubmitHandler}>
                <Stack spacing={3}>

                    <FormControl>
                        <TextField name="email"
                            value={otp}
                            // disabled
                            onChange={e => setOtp(e.target.value)}
                            required label="OTP" />
                    </FormControl>




                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    {/* <Checkbox name="remember"label="Remember me" />  */}
                    {
                        count > 0 ?
                            <Typography variant="p" color="initial">Otp send in {count}</Typography>
                            : <Link variant="subtitle2" underline="hover" onClick={reSendOtpHandle}>
                                Resend otp?
                            </Link>
                    }
                </Stack>

                {meLoading ?

                    (<LoadingButton fullWidth size="large" variant="text" >
                        <CircularProgress color='success' />
                    </LoadingButton>)
                    :

                    (<LoadingButton fullWidth size="large" type="submit" variant="contained" >
                        Verify
                    </LoadingButton>)

                }
            </form>



        </>
    );
}

import React, { useEffect } from 'react'
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Stack, Typography } from '@mui/material';
import ComingSoon from '../../images/imgEdu/coming-soon.jpg'
import './overviewUi.css'
import { clearError, getAllFilesDetails } from '../../redux/actions/filesAction';

const OverViewUi = ({ course }) => {
    const dispatch = useDispatch();
    const { courseId } = useParams()


    const { loading: fileLoading, file, error: fileErr } = useSelector((state) => state.getFilesData)

    useEffect(() => {
        dispatch(getAllFilesDetails(courseId))
    }, [courseId])

    useEffect(() => {
        if (fileErr) {
            toast.error(fileErr);
            dispatch(clearError());
        }
    }, [fileErr])
    // console.log(file&&file)
    return (
        <div className='overviewPage'>
            <h3>Overview</h3>
            <img src={course?.thumbnails?.url ? course.thumbnails?.url : ComingSoon} alt="" className='banner' />
            <div className="courseItem">
                <h5> <b>{course?.name}</b></h5>
                <p> {course?.title}</p>
                {/* <p> {const s = course?.htmls.toHtmlObject}</p> */}
                <div dangerouslySetInnerHTML={{ __html: course?.description }} />
            </div>
        <br />
            <Divider />

            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack width={'50%'} alignItems={'center'} justifyContent={'space-between'} padding={5}>
                    <Typography variant="p" color="initial"> Validity </Typography>
                    <Typography variant="p" color="initial">  {course?.expired} Months</Typography>
                </Stack>
                {/* <Divider  /> */}


                <Stack width={'50%'} alignItems={'center'} justifyContent={'center'}>

                    <Typography variant="p" color="initial">Free Content : {file?.free}</Typography>
                    <Typography variant="p" color="initial">Videos : {file?.videos} | Files : {file?.files}</Typography>
                    {/* <Typography variant="p" color="initial"></Typography> */}

                </Stack>

            </Stack>

        </div>
    )
}

export default OverViewUi
import React, { useEffect, useState } from 'react'
import './chapterMain.css'
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowLeft, FaArrowRight, FaEye, FaFolder, FaLock } from 'react-icons/fa';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { MdFileOpen, MdFolder, MdSettings, MdVideoFile } from 'react-icons/md';
import { clearError, getAllChapters, getAllChaptersFile, getChapters } from '../../redux/actions/filesAction';
import Iconify from '../../components/iconify';
import FileUi from './FileUi';

const ChapterUi = ({ course }) => {

  const dispatch = useDispatch();

  const { loading: chapLoading, chapters, error: chapErr } = useSelector((state) => state.getChapter)

  useEffect(() => {
    if (course) {
      dispatch(getChapters(course?._id))
    }
    if (chapErr) {
      toast.error(chapErr)
      clearError()
    }
    setChapterId('')
    // chapter()
  }, [course])




  // ------------------------- get files ---------------------
  const handleChapterId = (chapt) => {
    setChapterId(chapt)
    setIsToggle(!isToggle)
  }
  const { loading: fileLoading, files, error: fileErr } = useSelector((state) => state.getFiles)
  const [chapterId, setChapterId] = useState('')

  useEffect(() => {
    if (fileErr) {
      toast.error(fileErr)
      clearError()
    }
  }, [fileErr])

  useEffect(() => {
    dispatch(getAllChaptersFile(chapterId?._id))

  }, [chapterId])

  // console.log(files)
  const [file, setFile] = useState('')
  const [viewModalOpen, setViewModalOpen] = useState(false)
  const [isToggle, setIsToggle] = useState(true);


  const handleView = (file) => {
    if(file.isFree){

      setFile(file)
      vModalToggle()
    }
  }

  const vModalToggle = () => {
    setViewModalOpen(!viewModalOpen);
  }


  return (
    <div className='chapterMain'>

      {
        isToggle ?
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <h3>Content</h3>

            </Stack>
            <Stack direction="row" justifyContent="space-between">

              {
                chapters?.length === 0 ?
                  <div className='noChapter'>
                    <MdFolder /> Empty Chapter

                  </div>
                  :
                  <div className='chaptersBox'>
                    {
                      chapters && chapters.map((item, i) => (

                        <Button onClick={e => handleChapterId(item)}  key={i} className={files && (item._id === files[0]?.chapterId ? 'chapterItem chapterActive' : 'chapterItem')}>
                          <Stack direction={'row'} gap={2} alignItems={'center'}>

                          <FaFolder size={40} className='svg'/>
                          {item.name}
                          </Stack>

                          <Button onClick={e => handleChapterId(item)} className='bt n' variant='' size='large'>
                            <FaArrowRight size={15} />
                          </Button>


                        </Button>
                      ))
                    }

                  </div>
              }
            </Stack>

          </> :
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" paddingY={1}>
              <Button variant="outlined" onClick={e => setIsToggle(!isToggle)} size='large'>
                <FaArrowLeft />
              </Button>
              {/* <h3>Content</h3> */}
              <h4>{chapterId?.name}</h4>

            </Stack>
            <Stack direction="row" justifyContent="space-between">


              {
                (chapters?.length !== 0) ?
                  files?.length === 0 ?
                    <div className='nofiles'>
                      <MdFolder className='svg' /> Empty file
                    </div>
                    : <div className='filesBox'>
                      {
                        fileLoading ? "Loading" : ''
                      }
                      {
                        files?.map(item => (
                          <Stack width={'100%'} direction={'row'} borderRadius={1} bgcolor={'rgb(226, 226, 226)'} alignContent={'center'} alignSelf={'center'} justifyContent={'space-between'}>


                            <button className='fileItem' onClick={e => handleView(item)}>
                              <div>
                                <MdVideoFile size={20} />
                                {/* <a rel="noreferrer" target='_blank' href={item?.file?.url}> </a> */}
                                <Typography variant="p" color="initial">{item.fileName ? item.fileName : 'Unknown'}</Typography>
                              </div>
                            </button>

                            <Stack  alignSelf={'center'} paddingRight={2}>

                              {
                                !item.isFree ?
                                  <FaLock  />
                                  :
                                  <p className='active' style={{fontSize:'small'}}>Free</p>
                              }
                            </Stack>
                          </Stack>
                        ))
                      }
                    </div>
                  : ''
              }

            </Stack>
          </>
      }





      <Modal
        open={viewModalOpen}
        // onClose={MemberModalHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='bgb'>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <div> </div>
            <Button onClick={e => vModalToggle()} style={{ color: 'white' }} variant="outlined" >close</Button>
          </Stack>

          <FileUi file={file} />
        </Box>
      </Modal>

    </div>
  )
}

export default ChapterUi
export const CREATE_CHAPTER_REQUEST = "CREATE_CHAPTER_REQUEST"
export const CREATE_CHAPTER_SUCCESS = "CREATE_CHAPTER_SUCCESS"
export const CREATE_CHAPTER_FAIL = "CREATE_CHAPTER_FAIL"
export const CREATE_CHAPTER_RESET = "CREATE_CHAPTER_RESET"

export const GET_CHAPTER_REQUEST = "GET_CHAPTER_REQUEST"
export const GET_CHAPTER_SUCCESS = "GET_CHAPTER_SUCCESS"
export const GET_CHAPTER_FAIL = "GET_CHAPTER_FAIL"

export const GET_FILES_ADMIN_REQUEST = "GET_FILES_ADMIN_REQUEST"
export const GET_FILES_ADMIN_SUCCESS = "GET_FILES_ADMIN_SUCCESS"
export const GET_FILES_ADMIN_FAIL = "GET_FILES_ADMIN_FAIL"

export const GET_ALL_CHAPTER_REQUEST = "GET_ALL_CHAPTER_REQUEST"
export const GET_ALL_CHAPTER_SUCCESS = "GET_ALL_CHAPTER_SUCCESS"
export const GET_ALL_CHAPTER_FAIL = "GET_ALL_CHAPTER_FAIL"

export const UPDATE_CHAPTER_REQUEST = "UPDATE_CHAPTER_REQUEST"
export const UPDATE_CHAPTER_SUCCESS = "UPDATE_CHAPTER_SUCCESS"
export const UPDATE_CHAPTER_FAIL = "UPDATE_CHAPTER_FAIL"
export const UPDATE_CHAPTER_RESET = "UPDATE_CHAPTER_RESET"


//  files

export const UPLOAD_FILES_REQUEST = "UPLOAD_FILES_REQUEST"
export const UPLOAD_FILES_SUCCESS = "UPLOAD_FILES_SUCCESS"
export const UPLOAD_FILES_FAIL = "UPLOAD_FILES_FAIL"
export const UPLOAD_FILES_RESET = "UPLOAD_FILES_RESET"

export const TRACK_FILE_REQUEST = "TRACK_FILE_REQUEST"
export const TRACK_FILE_SUCCESS = "TRACK_FILE_SUCCESS"
export const TRACK_FILE_FAIL = "TRACK_FILE_FAIL"
export const TRACK_FILE_RESET = "TRACK_FILE_RESET"

export const GET_CHAPTER_FILES_REQUEST = "GET_CHAPTER_FILES_REQUEST"
export const GET_CHAPTER_FILES_SUCCESS = "GET_CHAPTER_FILES_SUCCESS"
export const GET_CHAPTER_FILES_FAIL = "GET_CHAPTER_FILES_FAIL"

export const GET_PAID_CHAPTER_FILES_REQUEST = "GET_PAID_CHAPTER_FILES_REQUEST"
export const GET_PAID_CHAPTER_FILES_SUCCESS = "GET_PAID_CHAPTER_FILES_SUCCESS"
export const GET_PAID_CHAPTER_FILES_FAIL = "GET_PAID_CHAPTER_FILES_FAIL"

export const GET_FILE_REQUEST = "GET_FILE_REQUEST"
export const GET_FILE_SUCCESS = "GET_FILE_SUCCESS"
export const GET_FILE_FAIL = "GET_FILE_FAIL"


export const GET_FILES_DETAILS_REQUEST = "GET_FILES_DETAILS_REQUEST"
export const GET_FILES_DETAILS_SUCCESS = "GET_FILES_DETAILS_SUCCESS"
export const GET_FILES_DETAILS_FAIL = "GET_FILES_DETAILS_FAIL"

export const UPDATE_FILE_REQUEST = "UPDATE_FILE_REQUEST"
export const UPDATE_FILE_SUCCESS = "UPDATE_FILE_SUCCESS"
export const UPDATE_FILE_FAIL = "UPDATE_FILE_FAIL"
export const UPDATE_FILE_RESET = "UPDATE_FILE_RESET"

export const CLEAR_ERROR = "CLEAR_ERROR"
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { profiletReducers, usertReducers, forgotPasswordReducers, allUsersReducers, userDetailsReducers, createUserReducer, allUserReducers, bulkImportReducers, memberRegistrationReducers, memberEmailVerifyReducers, renewReducers, emailVerify, enrolledUsers } from './reducers/userReducer'
import { actionCategory, actionCourse, getCategories, getCourses , getCategory, getCourse, actionEnroll, isEnrollCourse} from './reducers/courseReducer';
import { actionChapter, actionFile, getChapter, getFiles , getFile, getFilesData} from './reducers/filesReducers';
import { getPayments } from './reducers/paymentReducer';



const reducer = combineReducers({
    profile: profiletReducers,
    users: usertReducers,
    allUsers: allUsersReducers,
    forgetPassword: forgotPasswordReducers,
    createUser: createUserReducer,
    getUser: userDetailsReducers,
    allUsersForExc: allUserReducers,
    email:emailVerify,
    enrolledUsers,
    // -----------------

    // course and category 
    actionCourse,
    get:getCourse,
    getCourses,
    actionCategory,
    getCategories,
    getCategory,


// chapter and files
    actionChapter,
    getChapter,

    getFile,
    getFiles,
    actionFile,
    getFilesData,

    actionEnroll,
    isEnrollCourse,

    // payments
    getPayments,
    
});

const intitialeState = {

};

const middleware = [thunk]

const store = createStore(
    reducer,
    intitialeState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store;

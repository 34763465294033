import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import CourseMainPage from './pages/course/CourseMainPage';
import CoursePage from './pages/course/CoursePage';
import CategoryPage from './pages/category/CategoryPage';
import LoginPage from './pages/LoginPage';
// import EmailVerifyPage from './pages/EmailVerifyPage';

import CourseStorePage from './pages/store/CourseStorePage';
import CourseForUserPage from './pages/store/CourseForUserPage';
import EnrolledCoursesPage from './pages/store/EnrolledCoursesPage';
import EnrolledCourseIdPage from './pages/store/EnrolledCourseIdPage';
import EnrolledUsersPage from './pages/store/EnrolledUsersPage';

import PaymentSuccessPage from './pages/payment/PaymentSuccessPage';
import PaymentsPage from './pages/payment/PaymentsPage';

import Page404 from './pages/Page404';



// users routes start
import DashboardAppPage from './pages/DashboardAppPage';
import UserNew from './pages/Users/UserNew';
import UserInvite from './pages/Users/UserInvite';
import UserEdit from './pages/Users/UserEdit';
import ForgetPassword from './pages/Users/ForgetPassword';
import AllUsersPage from './pages/Users/AllUsersPage'





// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'course-store', element: <CourseStorePage /> },
        { path: 'app', element: <DashboardAppPage /> },
        // for admin  manage
        { path: 'course', element: <CourseMainPage /> },
        { path: 'view-course/:courseId', element: <CoursePage /> },
        { path: 'enrolled-users/:courseId', element: <EnrolledUsersPage /> },
        { path: 'user', element: <AllUsersPage /> },
        { path: 'category', element: <CategoryPage /> },
        
        // user
        { path: 'enrolled-course', element: <EnrolledCoursesPage /> },
        { path: 'course/u/:courseId', element: <CourseForUserPage /> },
        { path: 'enrolled-course/:courseId', element: <EnrolledCourseIdPage /> },

        { path: 'payments', element: <PaymentsPage /> },

      ],
    },
   

    {
      path: '/user',
      children: [
        { path: 'new', element: <UserNew /> },
        { path: 'invite/:token', element: <UserInvite /> },
        { path: 'edit/:id', element: <UserEdit /> },
        { path: 'password/reset/:token', element: <ForgetPassword /> },
      ],
    },
   
    {
      index: true,
      path: '/',
      element: <LoginPage />,
    },
    {
      path: '/paymentsuccess/:id',
      element: <PaymentSuccessPage />,
    },
    

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}

import React, { useEffect, useRef, useState } from 'react'
import './newCourse.css'
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Pagination,
    Box,
    Grid,
    CircularProgress,
    Divider,
    Modal,
    MenuItem,
    Select,
    InputLabel,
    Switch,
    FormControl,
    OutlinedInput,
    Chip,


} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearError, createCourse, getAllCourse, getAvailCategory } from '../../redux/actions/courseAction';
import { CREATE_COURSE_RESET } from '../../redux/constants/courseConstant';
import TextEditor from '../../components/TextEditor';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    // height: '90vh',
    overFlow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const NewCourseUi = ({ modalOpen, setModalOpen }) => {
    // -----------------------------new Member Modal Code --------------------------------------

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const { loading, success, error } = useSelector((state) => state.actionCourse)
    const { user } = useSelector((state) => state.users)
    const { loading: catLoading, categories, error: catErr } = useSelector((state) => state.getCategories)

    useEffect(() => {
        if (catErr) {
            toast.error(catErr);
            dispatch(clearError());
        }
        dispatch(getAvailCategory())
    }, [])

    useEffect(() => {
        if (success) {
            toast.success('success');
            MemberModalHandleClose()
            dispatch({ type: CREATE_COURSE_RESET });
            dispatch(getAllCourse())
        }
        // if (error) {
        //     toast.error(error);
        //     dispatch(clearError());
        // }
    }, [error, success, dispatch])


    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [html, setHtml] = useState('');
    // let html;
    const htmlRef = useRef('');

    const [isPaid, setIsPaid] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [discount, setDiscount] = useState('');
    const [price, setPrice] = useState(0);
    const [category, setCategory] = useState([]);
    const [categoryPreview, setCategoryPreview] = useState([]);
    const [expired, setExpired] = useState(0);

    const [file, setFile] = useState('');
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            //   if (
            //     file.type === "application/vnd.ms-excel" ||
            //     file.type ===
            //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            //   ) {
            if (file.size <= 2 * 1024 * 1024) {

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    setFile(e.target.result);
                    // console.log(selectedFile)
                }
            } else {
                event.target.value = null;
                setFile(null);
                alert("File size exceeds the limit of 5 MB.");
            }
        } else {
            event.target.value = null;
            setFile(null);
            alert("Please select an  file.");
        }
        // }
    };



    const MemberModalHandleClose = () => {
        setModalOpen(false);
    }
    // console.log(categories)


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        // console.log(value)

        setCategory(
            // On autofill we get a stringified value.
            value
            // ["hii"]

        );
    };

    const submitHandler = (e) => {
        e.preventDefault()

        const categories = [];

        category.forEach(item => {
            categories.push(item._id)
        })
        // console.log(htmlRef.current)
        if (!isPaid) {
            setPrice(0)
            setDiscount(0)
        }

        // if (isPaid) {
        if (isPaid && price < 1) {
            toast.error("Price should greater then 0");
        }
        else if (isPaid && discount < 1 || discount > 99) {
            toast.error("Discount should greater then 0 and less then 100");
        } else if (expired < 1) {
            toast.error("Expired should greater then 0");
        }
        // }
        else {
            dispatch(createCourse({ name, expired, title, isPaid, isActive, file, discount:!isPaid ? 0 : discount, price:!isPaid ? 0 : price, description: htmlRef.current, created_by: user?.name, categories }))
        }
    }

    // console.log(html)
    return (

        <div>
            <Modal
                open={modalOpen}
                // onClose={MemberModalHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="courseScroll">
                    <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            Create New Course
                        </Typography>
                        <Button onClick={MemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                    </Stack>

                    <Divider />

                    <form action="" onSubmit={submitHandler}>
                        <Stack spacing={3} margin='1vmax 0'>
                            <TextField
                                size='small'
                                value={name}
                                onChange={e => setName(e.target.value)}
                                required label="Course name" />
                        </Stack>
                        <Stack spacing={3} margin='1vmax 0'>
                            <TextField
                                size='small'
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                required label="Heading/  Title" />
                        </Stack>


                        <Stack spacing={3} margin='1vmax 0'>
                            <TextField
                                size='small'
                                type='number'

                                value={expired}
                                onChange={e => setExpired(e.target.value)}
                                required label="Course Expired (in Months)" />
                        </Stack>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="demo-multiple-chip-label">Select Categories</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={category}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Select Categories" />}

                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value._id} label={value.categoryName} />
                                        ))}
                                    </Box>
                                )}
                            // MenuProps={MenuProps}
                            >
                                {categories?.map((item) => (
                                    <MenuItem
                                        key={item._id}
                                        value={item}
                                    // style={getStyles(name, personName, theme)}
                                    >
                                        {item.categoryName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Stack spacing={3} margin='1vmax 0' direction={'row'} alignItems={'center'}>
                            <Typography id="modal-modal-title" variant="p" component="h4">Is Paid</Typography>
                            <Switch
                                checked={isPaid}
                                onChange={e => setIsPaid(!isPaid)}
                            />
                        </Stack>
                        {
                            isPaid &&
                            <>
                                <Stack spacing={3} margin='1vmax 0'>
                                    <TextField
                                        size='small'
                                        type='number'

                                        value={price}
                                        onChange={e => setPrice(e.target.value)}
                                        required label="Price" />
                                </Stack>

                                <Stack spacing={3} margin='1vmax 0'>
                                    <TextField
                                        size='small'
                                        type='number'
                                        value={discount}
                                        onChange={e => setDiscount(e.target.value)}
                                        required label="Discount" />
                                </Stack>

                                <Typography variant="h6" color="initial">
                                    {
                                        (price < 1 || discount < 1 || discount > 99) ? <p style={{ color: 'red' }}>Price or Discount should greater then 0 and discount less then 100</p> :
                                            <>Price after discount : {price - (price * discount / 100)}</>
                                    }
                                </Typography>
                            </>
                        }

                        <Stack spacing={3} margin='1vmax 0' direction={'row'} alignItems={'center'}>
                            <Typography id="modal-modal-title" variant="p" component="h4">Is Active</Typography>
                            <Switch
                                checked={isActive}
                                onChange={e => setIsActive(!isActive)}
                            />
                        </Stack>

                        <Stack spacing={3} margin='1vmax 0' direction={'row'} padding={3} borderRadius={2} className='bg' justifyContent={'space-between'} alignItems={'center'}>
                            <Stack spacing={3} margin='1vmax 0' >
                                <input
                                    className="intFileBtn"
                                    required
                                    type="file"
                                    accept=".png , .jpg,"
                                    onChange={handleFileChange}
                                    name="file"
                                    placeholder="k"
                                />
                                <p className="fileName">Select Only .jpg, .png</p>
                            </Stack>
                            {
                                file && <img src={file} alt="" height={200} />
                            }
                        </Stack>

                        <TextEditor content={html} setContent={setHtml} htmlRef={htmlRef} />
                        <br />

                        {loading ?

                            (<LoadingButton fullWidth size="large" variant="text" >
                                <CircularProgress color='success' />
                            </LoadingButton>)
                            :

                            (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                create
                            </LoadingButton>)

                        }
                    </form>
                </Box>
            </Modal>
        </div >
    )
}

export default NewCourseUi
import React, { useEffect, useState } from 'react'
import './chapterMain.css'
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowAltCircleLeft, FaArrowRight, FaFolder } from 'react-icons/fa';
import { Button, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { MdFileOpen, MdFolder, MdSettings, MdVideoFile } from 'react-icons/md';
import { clearError, getAllChapters, getAllChaptersFile, getAllPaidChaptersFile } from '../../redux/actions/filesAction';
import Iconify from '../../components/iconify';

const ChapterUi = ({ course, setFile,file }) => {
    const dispatch = useDispatch();

    const { loading: chapLoading, chapters, error: chapErr } = useSelector((state) => state.getChapter)

    useEffect(() => {
        if (course) {
            dispatch(getAllChapters(course?._id))
        }
        if (chapErr) {
            toast.error(chapErr)
            clearError()
        }
        setChapterId('')
        // chapter()
    }, [course])




    // ------------------------- get files ---------------------
    const handleChapterId = (chapt) => {
        setChapterId(chapt)
        isActiveToggle()
    }
    const { loading: fileLoading, files, error: fileErr } = useSelector((state) => state.getFiles)
    const [chapterId, setChapterId] = useState('')

    useEffect(() => {
        if (fileErr) {
            toast.error(fileErr)
            clearError()
        }
    }, [fileErr])

    useEffect(() => {
        if (chapterId && course) {
            console.log(chapterId?._id, course?._id)
            dispatch(getAllPaidChaptersFile(chapterId?._id, course?._id))
        }
    }, [chapterId])

    // console.log(files)
    //   -------------------------------------------------------------------------

    const [active, setActive] = useState(false)

    const isActiveToggle = () => {
        setActive(!active)
    }

    return (
        <div className='chapterMain'>

            <Stack direction="row" alignItems="center" paddingY={2} justifyContent="space-between">
                <Typography variant="h6" color="initial">Content</Typography>
            </Stack>

            <Stack >

                <div className={active && 'chapterHide'}>
                    {
                        chapters?.length === 0 ?
                            <div className='noChapter'>
                                <MdFolder /> Empty Chapter
                            </div>
                            :
                            <div className='chaptersBox'>
                                {
                                    chapters && chapters.map((item, i) => (
                                        <Button onClick={e => handleChapterId(item)} key={i} className={files ? (item?._id === files[0]?.chapterId ? 'chapterItem chapterActive' : 'chapterItem') : 'chapterItem'}>
                                            <Stack direction={'row'} alignItems={'center'} gap={1}>
                                            <FaFolder className='svg' />
                                            <Typography variant="p" color="gray">{item.name}</Typography>
                                            </Stack>
                                            <Button onClick={e => handleChapterId(item)} className='b tn'  size='medium'>
                                                <FaArrowRight />
                                            </Button>
                                        </Button>
                                    ))
                                }

                            </div>
                    }
                </div>


                <div className={!active && 'chapterHide'} >
                    <div>
                        <FaArrowAltCircleLeft size={23} onClick={isActiveToggle} />
                    </div>
                    {
                        files?.length === 0 ?
                            <div className='noFileChapter'>
                                <MdFolder /> Empty file
                            </div>
                            :
                            <div className='filesBoxChapter'>
                                <h4>{chapterId?.name}</h4>
                                {
                                    fileLoading ? "Loading" : ''
                                }
                                {
                                    files?.map(item => (
                                        <button className={file?._id === item._id ? 'fileItem activeFile' : 'fileItem'} onClick={e => setFile(item)}>
                                            <div >
                                                <MdVideoFile size={22} />
                                               <Typography variant="p" color="initial">{item.fileName ? item.fileName : 'Unknown'}</Typography> 
                                            </div>
                                        </button>
                                    ))
                                }
                            </div>
                    }
                </div>


            </Stack>
        </div>
    )
}

export default ChapterUi
export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST"
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS"
export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL"
export const CREATE_CATEGORY_RESET = "CREATE_CATEGORY_RESET"

export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL"

export const GET_ALL_CATEGORY_REQUEST = "GET_ALL_CATEGORY_REQUEST"
export const GET_ALL_CATEGORY_SUCCESS = "GET_ALL_CATEGORY_SUCCESS"
export const GET_ALL_CATEGORY_FAIL = "GET_ALL_CATEGORY_FAIL"


export const GET_AVAIL_CATEGORY_REQUEST = "GET_AVAIL_CATEGORY_REQUEST"
export const GET_AVAIL_CATEGORY_SUCCESS = "GET_AVAIL_CATEGORY_SUCCESS"
export const GET_AVAIL_CATEGORY_FAIL = "GET_AVAIL_CATEGORY_FAIL"

export const GET_COURSE_CATEGORY_REQUEST = "GET_COURSE_CATEGORY_REQUEST"
export const GET_COURSE_CATEGORY_SUCCESS = "GET_COURSE_CATEGORY_SUCCESS"
export const GET_COURSE_CATEGORY_FAIL = "GET_COURSE_CATEGORY_FAIL"

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL"
export const UPDATE_CATEGORY_RESET = "UPDATE_CATEGORY_RESET"

// course constant 

export const CREATE_COURSE_REQUEST = "CREATE_COURSE_REQUEST"
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS"
export const CREATE_COURSE_FAIL = "CREATE_COURSE_FAIL"
export const CREATE_COURSE_RESET = "CREATE_COURSE_RESET"

export const GET_COURSE_REQUEST = "GET_COURSE_REQUEST"
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS"
export const GET_COURSE_FAIL = "GET_COURSE_FAIL"

export const GET_ALL_COURSE_REQUEST = "GET_ALL_COURSE_REQUEST"
export const GET_ALL_COURSE_SUCCESS = "GET_ALL_COURSE_SUCCESS"
export const GET_ALL_COURSE_FAIL = "GET_ALL_COURSE_FAIL"



export const GET_FILTER_COURSE_REQUEST = "GET_FILTER_COURSE_REQUEST"
export const GET_FILTER_COURSE_SUCCESS = "GET_FILTER_COURSE_SUCCESS"
export const GET_FILTER_COURSE_FAIL = "GET_COURSE_CATEGORY_FAIL"

export const UPDATE_COURSE_REQUEST = "UPDATE_COURSE_REQUEST"
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS"
export const UPDATE_COURSE_FAIL = "UPDATE_COURSE_FAIL"
export const UPDATE_COURSE_RESET = "UPDATE_COURSE_RESET"

export const DELETE_COURSE_REQUEST = "DELETE_COURSE_REQUEST"
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS"
export const DELETE_COURSE_FAIL = "DELETE_COURSE_FAIL"
export const DELETE_COURSE_RESET = "DELETE_COURSE_RESET"


export const RECOVER_COURSE_REQUEST = "RECOVER_COURSE_REQUEST"
export const RECOVER_COURSE_SUCCESS = "RECOVER_COURSE_SUCCESS"
export const RECOVER_COURSE_FAIL = "RECOVER_COURSE_FAIL"
export const RECOVER_COURSE_RESET = "RECOVER_COURSE_RESET"

export const ENROLL_COURSE_REQUEST = "ENROLL_COURSE_REQUEST"
export const ENROLL_COURSE_SUCCESS = "ENROLL_COURSE_SUCCESS"
export const ENROLL_COURSE_FAIL = "ENROLL_COURSE_FAIL"
export const ENROLL_COURSE_RESET = "ENROLL_COURSE_RESET"

export const GET_ENROLL_COURSE_REQUEST = "GET_ENROLL_COURSE_REQUEST"
export const GET_ENROLL_COURSE_SUCCESS = "GET_ENROLL_COURSE_SUCCESS"
export const GET_ENROLL_COURSE_FAIL = "GET_ENROLL_COURSE_FAIL"


export const GET_COURSE_DASHBOARD_REQUEST = "GET_COURSE_DASHBOARD_REQUEST"
export const GET_COURSE_DASHBOARD_SUCCESS = "GET_COURSE_DASHBOARD_SUCCESS"
export const GET_COURSE_DASHBOARD_FAIL = "GET_COURSE_DASHBOARD_FAIL"

export const IS_ENROLL_REQUEST = "IS_ENROLL_REQUEST"
export const IS_ENROLL_SUCCESS = "IS_ENROLL_SUCCESS"
export const IS_ENROLL_FAIL = "IS_ENROLL_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR"
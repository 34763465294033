import axios from "axios";
import {
    CLEAR_ERROR,

    GET_PAYMENT_REQUEST,
    GET_PAYMENT_FAIL,
    GET_PAYMENT_SUCCESS,
    GET_ALL_PAYMENTS_REQUEST,
    GET_ALL_PAYMENTS_FAIL,
    GET_ALL_PAYMENTS_SUCCESS,

} from '../constants/paymentsConstant'

import { host } from "../../utils/config";


export const getPayment = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_PAYMENT_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.get(`${host}/payment/api/v1/payment/${id}`, config);

        dispatch({ type: GET_PAYMENT_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_PAYMENT_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getAllPayments = (userId) => async (dispatch) => {
    // console.log(userId)
    try {
        dispatch({ type: GET_ALL_PAYMENTS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.get(`${host}/payment/api/v1/all-payments/${userId}`, config);

        // console.log(data)

        dispatch({ type: GET_ALL_PAYMENTS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_ALL_PAYMENTS_FAIL,
            payload: error?.response?.data.message,
        });
    }
};









export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};
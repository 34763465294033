import React, { useEffect, useRef, useState } from 'react'
import './courseModal.css'
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Pagination,
    Box,
    Grid,
    CircularProgress,
    Divider,
    Modal,
    MenuItem,
    Select,
    InputLabel,
    Switch,
    FormControl,
    OutlinedInput,
    Chip,


} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useNavigate } from 'react-router-dom';
import buyCourseHandle from '../../utils/buyCourseHandle';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs:'98%', sm:'50%'},
    overFlow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};
const NewCourseUi = ({ modalOpen, setModalOpen, course, user }) => {
    // -----------------------------new Member Modal Code --------------------------------------

    const navigate = useNavigate();

    const buyHandle = () => buyCourseHandle(user, course, total)
    const [discount, setDiscount] = useState(0)
    const [total, setTotal] = useState(0)

    const [coupon, setCoupon] = useState('')

    useEffect(() => {
        setDiscount(course?.price * course?.discount / 100)
        setTotal(course?.price - (course?.price * course?.discount / 100))

    }, [course])

    return (

        <div>
            <Modal
                open={modalOpen}
                // onClose={MemberModalHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <Stack justifyContent={'flex-end'} alignItems={'flex-end'}>

                        <Button onClick={e => setModalOpen(!modalOpen)}>X</Button>
                    </Stack>
                    <Box className="buyBox">

                        <Stack paddingY={2}>
                            <Typography variant="h4" textAlign={'center'} color="initial">{course?.name}</Typography>
                        </Stack>

                        <Divider />

                        <Stack spacing={1} paddingX={{sm:5 , xs:2}} paddingY={2}>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant="p" color="initial">Name</Typography>
                                <Typography variant="p" color="initial">{user?.name}</Typography>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant="p" color="initial">Email</Typography>
                                <Typography variant="p" color="initial">{user?.email}</Typography>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant="p" color="initial">Phone</Typography>
                                <Typography variant="p" color="initial">{user?.phone}</Typography>
                            </Stack>
                        </Stack>

                        <Divider />


                        <Stack spacing={1} paddingX={{sm:5 , xs:2}} paddingY={2}>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant="p" color="initial">Amount</Typography>
                                <Typography variant="p" color="initial">₹{course?.price}</Typography>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant="p" color="initial">Discount</Typography>
                                <Typography variant="p" color="initial">- ₹{discount}</Typography>
                            </Stack>

                            <Divider />


                            <Stack spacing={1} paddingX={0} paddingY={2} >
                            <p>Make it later ?</p>

                                <form action="" style={{ width: '100%' }}>
                                    <FormControl direction='row' style={{ width: '100%' }}>
                                        <Stack spacing={1} direction={'row'} width={'100%'} justifyContent={'space-between'}>

                                            <TextField
                                                width={'80%'}
                                                id=""
                                                label="Have a Coupon Code ?"
                                                value={coupon}
                                                onChange={e => setCoupon(e.target.value)}
                                            />
                                            <LoadingButton width={'20%'} size='large' type="submit" variant="contained" >
                                                Apply
                                            </LoadingButton>
                                        </Stack>
                                    </FormControl>
                                </form>
                            </Stack>
                        </Stack>


                        <Divider />
                        <Stack spacing={1} paddingX={{sm:5 , xs:2}} paddingY={2}>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant="h6" color="initial">Total</Typography>
                                <Typography variant="h6" color="initial">₹{total}</Typography>
                            </Stack>
                        </Stack>

                        <Stack textAlign={'center'} alignItems={'center'} justifyContent={'center'} marginTop={2}>
                            <Button variant='contained' width={20} color='success' sx={{ color: 'white' }} size='large' onClick={buyHandle}> Pay Now</Button>
                        </Stack>

                    </Box>
                </Box>

            </Modal>
        </div >
    )
}

export default NewCourseUi
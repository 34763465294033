import axios from "axios";
import {


    DELETE_COURSE_REQUEST,
    DELETE_COURSE_SUCCESS,
    DELETE_COURSE_FAIL,

    RECOVER_COURSE_REQUEST,
    RECOVER_COURSE_SUCCESS,
    RECOVER_COURSE_FAIL,
    CLEAR_ERROR,

    CREATE_COURSE_REQUEST,
    CREATE_COURSE_SUCCESS,
    CREATE_COURSE_FAIL,
    GET_ALL_COURSE_REQUEST,
    GET_ALL_COURSE_SUCCESS,
    GET_ALL_COURSE_FAIL,
    GET_COURSE_REQUEST,
    GET_COURSE_SUCCESS,
    GET_COURSE_FAIL,
    GET_FILTER_COURSE_REQUEST,
    GET_FILTER_COURSE_SUCCESS,
    GET_FILTER_COURSE_FAIL,
    UPDATE_COURSE_SUCCESS,
    UPDATE_COURSE_REQUEST,
    UPDATE_COURSE_FAIL,
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAIL,
    GET_ALL_CATEGORY_REQUEST,
    GET_ALL_CATEGORY_SUCCESS,
    GET_ALL_CATEGORY_FAIL,
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,

    GET_AVAIL_CATEGORY_REQUEST,
    GET_AVAIL_CATEGORY_FAIL,
    GET_AVAIL_CATEGORY_SUCCESS,

    GET_COURSE_CATEGORY_REQUEST,
    GET_COURSE_CATEGORY_SUCCESS,
    GET_COURSE_CATEGORY_FAIL,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAIL,
    ENROLL_COURSE_REQUEST,
    ENROLL_COURSE_SUCCESS,
    ENROLL_COURSE_FAIL,
    GET_ENROLL_COURSE_REQUEST,
    GET_ENROLL_COURSE_SUCCESS,
    GET_ENROLL_COURSE_FAIL,
    IS_ENROLL_REQUEST,
    IS_ENROLL_SUCCESS,
    IS_ENROLL_FAIL,
    GET_COURSE_DASHBOARD_REQUEST,
    GET_COURSE_DASHBOARD_SUCCESS,
    GET_COURSE_DASHBOARD_FAIL,

} from '../constants/courseConstant'

import { host } from "../../utils/config";


export const createCategory = (details) => async (dispatch) => {

    try {
        // console.log("create member")

        dispatch({ type: CREATE_CATEGORY_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.post(`${host}/course/api/v1/category`, details, config);

        dispatch({ type: CREATE_CATEGORY_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: CREATE_CATEGORY_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getAllCategory = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_CATEGORY_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.get(`${host}/course/api/v1/all-categories`, config);

        dispatch({ type: GET_ALL_CATEGORY_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_ALL_CATEGORY_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getCategory = (categoryId) => async (dispatch) => {
    try {
        dispatch({ type: GET_CATEGORY_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.get(`${host}/course/api/v1/category/${categoryId}`, config);

        dispatch({ type: GET_CATEGORY_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_CATEGORY_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getAvailCategory = () => async (dispatch) => {
    try {
        dispatch({ type: GET_AVAIL_CATEGORY_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.get(`${host}/course/api/v1/categories`, config);

        dispatch({ type: GET_AVAIL_CATEGORY_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_AVAIL_CATEGORY_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getCourseCategory = () => async (dispatch) => {
    try {
        dispatch({ type: GET_COURSE_CATEGORY_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.get(`${host}/course/api/v1/course-category`, config);

        dispatch({ type: GET_COURSE_CATEGORY_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_COURSE_CATEGORY_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const updateCategory = (details, categoryId) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_CATEGORY_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.put(`${host}/course/api/v1/category/${categoryId}`, details, config);

        dispatch({ type: UPDATE_CATEGORY_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: UPDATE_CATEGORY_FAIL,
            payload: error?.response?.data.message,
        });
    }
};
// course

export const createCourse = (details) => async (dispatch) => {

    try {
        // console.log("create member")

        dispatch({ type: CREATE_COURSE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.post(`${host}/course/api/v1/course`, details, config);

        dispatch({ type: CREATE_COURSE_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: CREATE_COURSE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getAllCourse = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_COURSE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.get(`${host}/course/api/v1/courses`, config);

        dispatch({ type: GET_ALL_COURSE_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_ALL_COURSE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getCourse = (courseId) => async (dispatch) => {
    // console.log(courseId)
    try {
        dispatch({ type: GET_COURSE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.get(`${host}/course/api/v1/course/${courseId}`, config);

        dispatch({ type: GET_COURSE_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_COURSE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getFilterCourse = (cat, keyword) => async (dispatch) => {
    try {
        dispatch({ type: GET_FILTER_COURSE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.get(`${host}/course/api/v1/filter/courses?categories=${cat}&keyword=${keyword}`, config);

        dispatch({ type: GET_FILTER_COURSE_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_FILTER_COURSE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};


export const updateCourse = (details, id) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_COURSE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.put(`${host}/course/api/v1/course/${id}`, details, config);

        dispatch({ type: UPDATE_COURSE_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: UPDATE_COURSE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const deleteCourse = (id) => async (dispatch) => {
    try {

        dispatch({ type: DELETE_COURSE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.put(`${host}/course/api/v1/del-course/${id}`, {}, config);

        dispatch({ type: DELETE_COURSE_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: DELETE_COURSE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const recoverCourse = (id) => async (dispatch) => {
    try {

        dispatch({ type: RECOVER_COURSE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.put(`${host}/course/api/v1/recover-course/${id}`, {}, config);

        dispatch({ type: RECOVER_COURSE_SUCCESS, payload: data.success });

    } catch (error) {
        dispatch({
            type: RECOVER_COURSE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

//  courseId, userId
export const enrollCourse = (details) => async (dispatch) => {
    try {

        dispatch({ type: ENROLL_COURSE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.post(`${host}/course/api/v1/enroll`, details, config);

        dispatch({ type: ENROLL_COURSE_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: ENROLL_COURSE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const isEnrollCourse = (courseId, userId) => async (dispatch) => {
    try {

        dispatch({ type: IS_ENROLL_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        // console.log(`${host}/course/api/v1/is-enrolled?courseId=${courseId}&userId=${userId}`)
        const { data } = await axios.get(`${host}/course/api/v1/is-enrolled?courseId=${courseId}&userId=${userId}`, config);

        dispatch({ type: IS_ENROLL_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: IS_ENROLL_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getEnrolledCourses = (userId) => async (dispatch) => {
    try {

        dispatch({ type: GET_ENROLL_COURSE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.get(`${host}/course/api/v1/enrolled-courses/${userId}`, config);

        dispatch({ type: GET_ENROLL_COURSE_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_ENROLL_COURSE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};


export const getCourseDashboard = () => async (dispatch) => {
    try {

        dispatch({ type: GET_COURSE_DASHBOARD_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.get(`${host}/course/api/v1/course-dashboard`, config);

        dispatch({ type: GET_COURSE_DASHBOARD_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_COURSE_DASHBOARD_FAIL,
            payload: error?.response?.data.message,
        });
    }
};




export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};


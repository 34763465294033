import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import './categoryPage.css'
// @mui
import {
    Stack,
    Button,
    Container,
    Grid,
    CircularProgress,
} from '@mui/material';


import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { MdDelete, MdModeEdit, MdSettings, MdSettingsBackupRestore } from 'react-icons/md'
// components
import { FaEye } from 'react-icons/fa';
import { AppWidget } from '../../sections/@dashboard/app';
import Iconify from '../../components/iconify';
// sections
import { getAllCategory, clearError } from '../../redux/actions/courseAction';

import NewCategoryUi from './NewCategoryUi'
import UpdateCategoryUi from './UpdateCategoryUi'


// ----------------------------------------------------------------------

export default function CategoryPage() {

    const [category, setCategory] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);


    const CategoryModalHandleOpen = () => {
        setModalOpen(true)
    };


    const editHandle = (item) => {
        setCategory(item)
        setUpdateModalOpen(true)
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllCategory())
    }, [dispatch])

    const { loading, categories, error: getErr } = useSelector((state) => state.getCategories)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr);
            dispatch(clearError());
        }
    }, [getErr, navigate, dispatch])



    // ----------------- Admin Authentication -----------------------------------------
    const { user } = useSelector((state) => state.users)
    if (user) {
        if (user.role !== 'admin') {
            navigate('/dashboard/app')
        }
    } else {
        navigate('/')
    }

    // ----------------- Admin Authentication end -----------------------------------------

    return (
        <>
            <Helmet>
                <title> Categorys </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div className='totalComponents'>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Total Categories" total={categories?.length === 0 ? '0' : categories?.length} icon={'ph:users-fill'} />
                        </Grid>
                    </div>

                    {/* */}
                    <Button variant="contained" onClick={CategoryModalHandleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                        New Category
                    </Button>
                    {/* </Link> */}
                </Stack>

                {/* <Card> */}
                <div className='tableTop'>
                    <div hidden={!loading ? 'true' : ''}>
                        <CircularProgress color="primary" />
                    </div>
                    <div>Category</div>
                    {/* 
                        <form onSubmit={searchHandler}>
                            <TextField type='text' onChange={e => setKeyword(e.target.value)} value={keyword} placeholder='Search user' size="small" label="Search user" variant="outlined" />
                        </form> */}
                    <div> </div>


                </div>
                {/* <Divider /> */}

                <div className="categoryContainers">
                    {
                        categories && categories.map((item) => (
                            <div className="news-Box">
                                <div className="categoryBox">
                                    <h4> <b>{item?.categoryName}</b></h4>
                                    <p className='center'> {item.isActive ? 'Active' : 'inActive '}</p>
                                    <button onClick={e => editHandle(item)} className='editBtn'>
                                        <MdSettings />
                                    </button>
                                </div>

                                {/* <div className="actionBox">
                                    <div className="dateBox">
                                        <p>Create : {item?.createAt}</p>
                                    </div>
                                    <p className='center'>Active : {item.isActive ? 'Yes' : 'No'}</p>
                                    <div className="icon-Box">

                                        {
                                             (<>
                                                <button onClick={e => editHandle(item)}>
                                                    <MdModeEdit />
                                                </button>
                                               
                                            </>)
                                        }


                                    </div>

                                </div> */}

                            </div>
                        ))
                    }


                </div>

            </Container>

            <NewCategoryUi modalOpen={modalOpen} setModalOpen={setModalOpen} />
            <UpdateCategoryUi category={category} updateModalOpen={updateModalOpen} setUpdateModalOpen={setUpdateModalOpen} />
        </>
    );
}

import React, { useEffect, useState } from 'react'
import './chapterMain.css'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CircularProgress, Typography, FormControl, InputLabel, MenuItem, Select, Stack, Switch, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { UPLOAD_FILES_RESET } from '../../redux/constants/filesConstant';
import { clearError, uploadVideo } from '../../redux/actions/filesAction';

const UploadVideoUi = ({ course }) => {
  const dispatch = useDispatch();

  const { loading, success, error } = useSelector((state) => state.actionFile)
  const { chapters } = useSelector((state) => state.getChapter)

  useEffect(() => {
    if (success) {
      toast.success('File Created success');
      // dispatch(getAllChapters())
      dispatch({ type: UPLOAD_FILES_RESET });
      setIsActive(false)
      setFileName('')
      setFileType('')
      setChapterId('')
      setYoutubeId('')
      setFile('')
      setIsFree(false)
    }
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [error, success, dispatch])


  const [chapterId, setChapterId] = useState('')
  const [fileType, setFileType] = useState('')
  const [fileName, setFileName] = useState('')
  const [isFree, setIsFree] = useState(false)
  const [file, setFile] = useState('')
  const [youtubeId, setYoutubeId] = useState('')
  const [isActive, setIsActive] = useState(false)


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      //   if (
      //     file.type === "application/vnd.ms-excel" ||
      //     file.type ===
      //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      //   ) {
      if (file.size <= 100 * 1024 * 1024) {

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
          setFile(e.target.result);
          // console.log(selectedFile)
        }
      } else {
        event.target.value = null;
        setFile(null);
        alert("File size exceeds the limit of 5 MB.");
      }
    } else {
      event.target.value = null;
      setFile(null);
      alert("Please select an  file.");
    }
    // }
  };



  const submitHandler = (e) => {
    e.preventDefault()
    // console.log({ courseId: course?._id, chapterId, fileType, file })

    // if (course)
    dispatch(uploadVideo({ courseId: course?._id, chapterId, fileType, file, fileName, isFree, youtubeId, isActive }))
  }


  return (
    <div className='chapterMain'>
      UploadVideo Ui


      <form action="" onSubmit={submitHandler}>
        <Stack spacing={3} margin='1vmax 0'>
          <TextField
            size='medium'
            value={fileName}
            onChange={e => setFileName(e.target.value)}
            required label="File name" />
        </Stack>
        <Stack spacing={3} margin='1vmax 0'>
          <FormControl sx={{}}>
            <InputLabel id="demo-simple-select-helper-label">Select Chapter</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={chapterId}
              label="Select Chapter"
              onChange={e => setChapterId(e.target.value)}
              required
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {
                chapters && chapters?.map(item => (

                  <MenuItem value={item?._id}>{item.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Stack>


        <Stack spacing={3} margin='1vmax 0'>
          <FormControl sx={{}}>
            <InputLabel id="demo-simple-select-helper-label">Is Free ?</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={isFree}
              label="is  free ?"
              onChange={e => setIsFree(e.target.value)}
              required
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>


              <MenuItem value={'true'}>Free</MenuItem>
              <MenuItem value={'false'}>Paid</MenuItem>


            </Select>
          </FormControl>
        </Stack>

        <Stack spacing={3} margin='1vmax 0'>
          <FormControl sx={{}}>
            <InputLabel id="demo-simple-select-helper-label">Select File </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={fileType}
              required
              label="Select Chapter"
              onChange={e => setFileType(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>


              <MenuItem value={'pdf'}>PDF</MenuItem>
              <MenuItem value={'youtube'}>YOUTUBE LINK ID</MenuItem>
              <MenuItem value={'img'}>IMAGE</MenuItem>
              <MenuItem value={'video'}>VIDEO</MenuItem>

            </Select>
          </FormControl>
        </Stack>


        {
          fileType === 'youtube' &&
          <>
            <Stack spacing={3} margin='1vmax 0'>
              <TextField
                size='medium'
                value={youtubeId}
                onChange={e => setYoutubeId(e.target.value)}
                required label="Youtube Link Id" />
            </Stack>
          </>


        }

        {
          (fileType !== 'youtube' && fileType) &&
          <>
            <Stack spacing={3} margin='1vmax 0' direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Stack spacing={3}  >
                <input
                  className="intFileBtn"
                  required
                  type="file"
                  // accept=""
                  onChange={e => setFile(e.target.files[0])}
                  name="file"
                  placeholder="k"
                />
                {/* <p className="fileName">Select Only .jpg, .png</p> */}
              </Stack>
              {
                //  file && <img src={file} alt="" height={200} />
              }
            </Stack>
          </>
        }

        <Stack spacing={3} margin='1vmax 0' direction={'row'} alignItems={'center'}>
          <Typography id="modal-modal-title" variant="p" component="h4">Is Active</Typography>
          <Switch
            checked={isActive}
            onChange={e => setIsActive(!isActive)}
          />
        </Stack>


        {loading ?

          (<LoadingButton fullWidth size="large" variant="text" >
            <CircularProgress color='success' />
          </LoadingButton>)
          :

          (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
            create
          </LoadingButton>)

        }
      </form>
    </div>
  )
}

export default UploadVideoUi
import React from 'react'
import { Card } from '@mui/material'
import YouTube from 'react-youtube';
import { FaInfo } from 'react-icons/fa';
import ReactPlayer from 'react-player'
import PdfViewer from '../../component/PdfViewer'

import './file.css'

const FileUi = ({ file }) => {
  // console.log(file)
  return (
    <div>
      <h5 className='fileHeading'><FaInfo className='infoIcon' /> {file?.fileName}</h5>
      {
        !file.fileType ?
          <Card>
            <div className='selectFile'>
              <p>Please select file</p>
            </div>

          </Card> : ''

      }
      {
        file?.fileType === 'youtube' ?
          <div className='fileBox'>
            <YouTube
              // videoId={fileDetail?.ytId}
              videoId={file.file?.url}

              className='youtube'
            // opts={{
            //   playerVars: { autoplay: 1 },
            // }}

            // onReady={onReady}
            // onStateChange={onStateChange}
            // ref={playerRef}
            />
          </div>
          : ''
      }
      {
        file?.fileType === 'video' ?
          <div className='fileBox'>
            {/* <ReactPlayer url={file.file?.url} />  */}
            <video className='videoFile' src={file.file?.url} controls key={file.file?.url} ><track kind="captions" /></video>
          </div>
          : ''
      }
      {
        file?.fileType === 'pdf' ?
          <>
            {/* pdf <PdfViewer pdfUrl={file.file?.url} /> */}

            <iframe id="pdf-viewer" width={'100%'} src={file.file?.url} frameBorder="0" title='PDF'> </iframe>

          </> : ''
      }
      {
        file?.fileType === 'img' ?
          <div className='imgBox'>
            <img src={file.file?.url} alt={file?.fileName} height={'100%'} />
          </div> : ''
      }
    </div>

  )
}

export default FileUi
import React, { useEffect, useState } from 'react'
// import './newChapter.css'
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Pagination,
    Box,
    Grid,
    CircularProgress,
    Divider,
    Modal,
    MenuItem,
    Select,
    InputLabel,
    Switch,
    FormControl,
    OutlinedInput,
    Chip,


} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearError, getAllChapters, updateChapter } from '../../redux/actions/filesAction';
import { CREATE_CHAPTER_RESET } from '../../redux/constants/filesConstant';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    // height: '90vh',
    overFlow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const UpdateChapterUi = ({ chapter, updateModalOpen: modalOpen, setUpdateModalOpen: setModalOpen }) => {

    const dispatch = useDispatch();


    const { loading, success, error } = useSelector((state) => state.actionChapter)


    useEffect(() => {
        if (success) {
            // toast.success('Created success');
            MemberModalHandleClose()
            dispatch({ type: CREATE_CHAPTER_RESET });

            if (chapter)dispatch(getAllChapters(chapter?.courseId))
        }
        // if (error) {
        //     toast.error(error);
        //     dispatch(clearError());
        // }
    }, [error, success, dispatch])


    const [name, setName] = useState('');
    const [isActive, setIsActive] = useState(false);





    const MemberModalHandleClose = () => {
        setModalOpen(false);
    }
    // console.log(categories)



    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateChapter({ name, isActive }, chapter?._id))
    }
    useEffect(() => {
        setName(chapter?.name)
        setIsActive(chapter?.isActive)
        console.log(chapter)

    }, [chapter])


    return (

        <div>
            <Modal
                open={modalOpen}
                // onClose={MemberModalHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            Update Chapter
                        </Typography>
                        <Button onClick={MemberModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                    </Stack>

                    <Divider />

                    <form action="" onSubmit={submitHandler}>
                        <Stack spacing={3} margin='1vmax 0'>
                            <TextField
                                size='small'
                                value={name}
                                onChange={e => setName(e.target.value)}
                                required label="Chapter name" />
                        </Stack>
                        <Stack spacing={3} margin='1vmax 0' direction={'row'} alignItems={'center'}>
                            <Typography id="modal-modal-title" variant="p" component="h4">Is Active</Typography>
                            <Switch
                                checked={isActive}
                                onChange={e => setIsActive(!isActive)}
                            />
                        </Stack>







                        {loading ?

                            (<LoadingButton fullWidth size="large" variant="text" >
                                <CircularProgress color='success' />
                            </LoadingButton>)
                            :

                            (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                Update
                            </LoadingButton>)

                        }
                    </form>
                </Box>
            </Modal>
        </div >
    )
}

export default UpdateChapterUi
import React, { useEffect, useState } from 'react'
// import './newChapter.css'
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Pagination,
    Box,
    Grid,
    CircularProgress,
    Divider,
    Modal,
    MenuItem,
    Select,
    InputLabel,
    Switch,
    FormControl,
    OutlinedInput,
    Chip,


} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearError, getAllChapters, getFilesForAdmin, updateChapter, updateFile } from '../../redux/actions/filesAction';
import { UPDATE_FILE_RESET } from '../../redux/constants/filesConstant';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    // height: '90vh',
    overFlow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const UpdateFileUi = ({ file: fileData, fileModalOpen: modalOpen, setFileModalOpen: setModalOpen }) => {
    const { chapters } = useSelector((state) => state.getChapter)

    const dispatch = useDispatch();


    const { loading, success, error } = useSelector((state) => state.actionFile)


    useEffect(() => {
        if (success) {
            // toast.success('Created success');
            modalHandleClose()
            dispatch({ type: UPDATE_FILE_RESET });
            if(fileData){ 
                dispatch(getAllChapters(fileData?.courseId))
                dispatch(getFilesForAdmin(fileData?.chapterId))
            }
        }

    }, [error, success, dispatch])



    const [chapterId, setChapterId] = useState('')
    const [fileType, setFileType] = useState('')
    const [fileName, setFileName] = useState('')
    const [isFree, setIsFree] = useState(false)
    const [file, setFile] = useState('')
    const [youtubeId, setYoutubeId] = useState('')
    const [isActive, setIsActive] = useState(false)




    const modalHandleClose = () => {
        setModalOpen(false);
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateFile({ fileName, fileType, youtubeId, isActive, file, chapterId }, fileData?._id,))
    }
    useEffect(() => {
        // setName(fileData?.fileName)
        setFileType(fileData?.fileType)
        setIsFree(fileData?.isFree)
        setFileName(fileData?.fileName)
        setYoutubeId(fileData?.file?.url)
        setIsActive(fileData?.isActive)
        setChapterId(fileData?.chapterId)
        // console.log({fileName,chapterId,isFree,youtubeId,isActive,fileType})
    }, [fileData])


    return (

        <div>
            <Modal
                open={modalOpen}
                // onClose={modalHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            Update File
                        </Typography>
                        <Button onClick={modalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
                    </Stack>

                    <Divider />


                    <form action="" onSubmit={submitHandler}>
                        <Stack spacing={3} margin='1vmax 0'>
                            <TextField
                                size='medium'
                                value={fileName}
                                onChange={e => setFileName(e.target.value)}
                                required label="File name" />
                        </Stack>
                        <Stack spacing={3} margin='1vmax 0'>
                            <FormControl sx={{}}>
                                <InputLabel id="demo-simple-select-helper-label">Select Chapter</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={chapterId}
                                    label="Select Chapter"
                                    onChange={e => setChapterId(e.target.value)}
                                    required
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {
                                        chapters && chapters?.map(item => (

                                            <MenuItem value={item?._id}>{item.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Stack>


                        <Stack spacing={3} margin='1vmax 0'>
                            <FormControl sx={{}}>
                                <InputLabel id="demo-simple-select-helper-label">Is Free ?</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={isFree}
                                    label="is  free ?"
                                    onChange={e => setIsFree(e.target.value)}
                                    required
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>


                                    <MenuItem value={'true'}>Free</MenuItem>
                                    <MenuItem value={'false'}>Paid</MenuItem>


                                </Select>
                            </FormControl>
                        </Stack>

                        <Stack spacing={3} margin='1vmax 0'>
                            <FormControl sx={{}}>
                                <InputLabel id="demo-simple-select-helper-label">Select File </InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={fileType}
                                    label="Select Chapter"
                                    onChange={e => setFileType(e.target.value)}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>


                                    <MenuItem value={'pdf'}>PDF</MenuItem>
                                    <MenuItem value={'youtube'}>YOUTUBE LINK ID</MenuItem>
                                    <MenuItem value={'img'}>IMAGE</MenuItem>
                                    <MenuItem value={'video'}>VIDEO</MenuItem>

                                </Select>
                            </FormControl>
                        </Stack>


                        {
                            fileType === 'youtube' &&
                            <>
                                <Stack spacing={3} margin='1vmax 0'>
                                    <TextField
                                        size='medium'
                                        value={youtubeId}
                                        onChange={e => setYoutubeId(e.target.value)}
                                        required label="Youtube Link Id" />
                                </Stack>
                            </>


                        }

                        {
                            (fileType !== 'youtube' && fileType) &&
                            <>
                                <Stack spacing={3} margin='1vmax 0' direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Stack spacing={3}  >
                                        <input
                                            className="intFileBtn"
                                            // required
                                            type="file"
                                            // accept=""
                                            onChange={e => setFile(e.target.files[0])}
                                            name="file"
                                            placeholder="k"
                                        />
                                        {/* <p className="fileName">Select Only .jpg, .png</p> */}
                                    </Stack>
                                    {
                                        //  file && <img src={file} alt="" height={200} />
                                    }
                                </Stack>
                            </>
                        }

                        <Stack spacing={3} margin='1vmax 0' direction={'row'} alignItems={'center'}>
                            <Typography id="modal-modal-title" variant="p" component="h4">Is Active</Typography>
                            <Switch
                                checked={isActive}
                                onChange={e => setIsActive(!isActive)}
                            />
                        </Stack>



                        {loading ?

                            (<LoadingButton fullWidth size="large" variant="text" >
                                <CircularProgress color='success' />
                            </LoadingButton>)
                            :

                            (<LoadingButton fullWidth size='medium' type="submit" variant="contained" >
                                Update
                            </LoadingButton>)

                        }
                    </form>
                </Box>
            </Modal>
        </div >
    )
}

export default UpdateFileUi
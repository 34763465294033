import { LoadingButton } from '@mui/lab';
import { Button, Card, CircularProgress, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ComingSoon from '../../images/imgEdu/coming-soon.jpg';
import { clearError, enrollCourse, getEnrolledCourses, isEnrollCourse } from '../../redux/actions/courseAction';
import { ENROLL_COURSE_RESET } from '../../redux/constants/courseConstant';
import BuyCourseModal from '../payment/BuyCourseModal';
import './buyUi.css';


const BuyCourseUi = ({ course }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.users)
  const { loading, success, error: getErr } = useSelector((state) => state.actionEnroll)

  useEffect(() => {
    if (success) {
      toast.success('Enrolled');
      dispatch({ type: ENROLL_COURSE_RESET });
      dispatch(getEnrolledCourses())
    }
    if (getErr) {
      toast.error(getErr);
      dispatch(clearError());
    }
  }, [getErr, navigate, dispatch, success])

  const enrollHandle = (e) => {
    e.preventDefault()
    dispatch(enrollCourse({ courseId: course?._id, userId: user?._id }))
  }


  // check isCourse enrolled or not
  const { loading: isEnLoading, isEnrolled, error } = useSelector((state) => state.isEnrollCourse)

  useEffect(() => {
    if (error) {
      // toast.error(error);
      dispatch(clearError());
    }
  }, [error])


  useEffect(() => {
    // console.log(course?._id , user?._id)
    if (course?._id && user?._id) {
      dispatch(isEnrollCourse(course._id, user._id));
    }
  }, [course, user])

  const [modalOpen, setModalOpen] = useState(false)




  return (
    <Card>

      <Stack padding={{xs:2, sm:2}}>

        <img src={course?.thumbnails?.url ? course.thumbnails?.url : ComingSoon} alt="" className='banner' />
        <div className="courseBuyItem">
          <h3> <b>{course?.name}</b></h3>
          {/* <h2>Rs {course?.price}</h2> */}
          <div className='priceBox'>
            {
              course?.price === 0 ?
                <h4 className='freetag'>Free</h4>
                :
                <>
                  <h4>₹{course?.price - ((course?.price * course?.discount) / 100)}</h4>
                  <p>₹{course?.price} </p>
                </>
            }

          </div>

          <>
            {
              course?.price !== 0 ?
                <p className='off'>{course?.discount}% off</p>
                : <span className='offGap'> </span>
            }
          </>

          <br />
          {
            course?.isPaid ?
              <Button disabled={isEnrolled} className='' fullWidth size='large' variant='contained' onClick={e => setModalOpen(!modalOpen)} >{isEnrolled ? 'Purchased' : 'Buy Now'}</Button>
              :
              // <Button sx={{width:'100%'}} color="main.success" variant="outline">Enroll </Button>
              <>
                {
                  loading ?

                    (<LoadingButton fullWidth size="large" variant="text" >
                      <CircularProgress color='success' />
                    </LoadingButton>)
                    :

                    (<LoadingButton fullWidth size='large' onClick={enrollHandle} disabled={isEnrolled} variant="contained" >
                      {isEnrolled ? 'Enrolled' : 'Enroll Now'}
                    </LoadingButton>)

                }
              </>

          }
        </div>

        <BuyCourseModal modalOpen={modalOpen} setModalOpen={setModalOpen} course={course} user={user} />

      </Stack>

    </Card>

  )
}

export default BuyCourseUi
import { Card, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MdOutlineHourglassBottom } from 'react-icons/md';


const EmptyCard = ({ title }) => {
    return (
        <div >
            <br />
            <Card >
                <Stack px={5} py={5} alignItems={'center'} justifyContent={'center'}>
                    <Stack padding={10} width={'100%'} alignItems={'center'} borderRadius={2} justifyContent={'center'} bgcolor={'lightblue'}>

                        <MdOutlineHourglassBottom size={40} color='white' />
                        {/* <Typography variant="h3"  color='gray'>Empty</Typography> */}
                        <Typography variant="h4" textAlign={'center'}  color='white'>{title}!</Typography>
                    </Stack>
                </Stack>
            </Card>
        </div>
    )
}

export default EmptyCard
import { Helmet } from 'react-helmet-async';
// import './contactForm.css'
import { useEffect, useState } from 'react';
import Pagination from "react-js-pagination";
import './paginationStyle.css'
// @mui
import {
    Card,
    CircularProgress,
    Container,
    Grid,
    Stack,
    TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';


import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// components
import { clearError, getAllEnrolledUser } from '../../redux/actions/userAction';
import { getDateTime } from '../../utils/getDate';

import { AppWidget } from '../../sections/@dashboard/app';
import EmptyCard from '../../component/EmptyCard';


// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    // height: '90vh',
    overflow: 'auto',
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function UserPage() {

    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');



    const { loading, users, resultPerPage, totalUser, error } = useSelector((state) => state.enrolledUsers)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { courseId } = useParams()
    // console.log(totalUser, resultPerPage, users)

    useEffect(() => {
        dispatch(getAllEnrolledUser(courseId, page, keyword))
    }, [dispatch, page])

    useEffect(() => {
        if (error) {
            toast.error(error)
            dispatch(clearError())
        }
        if (keyword === '') {
            dispatch(getAllEnrolledUser(courseId, page, keyword))
        }
    }, [error, navigate, dispatch, keyword])





    const columns = [
        { field: 'id', headerName: 'ID', width: 90, },
        { field: 'name', headerName: 'Name', width: 180 },
        { field: 'email', headerName: 'Email', width: 270 },
        {
            field: 'role',
            headerName: 'Role',
            width: 230,
        },



        // { field: 'status', headerName: 'Activity', width: 220 },

        // { field: 'message', headerName: 'Message', width: 170 },
        { field: 'createAt', headerName: 'Created At', width: 200 },


    ];
    const rows = [];


    users?.forEach((item, i) => {
        // const date = new Date(item?.createAt)

        rows.push({
            id: i + 1,
            id_: item._id,
            name: item.name,
            email: item.email,
            role: item.role,
            // status: item.status,
            createAt: getDateTime(item?.createAt),
        });

    });

    // searchHandler
    const searchHandler = (e) => {
        e.preventDefault();
        dispatch(getAllEnrolledUser(courseId, page, keyword))
    }
    // const handlePage = (event, value) => {
    //   // console.log(value)
    //   setPage(value);
    // };




    // ----------------- Admin Authentication -----------------------------------------
    const { user } = useSelector((state) => state.users)
    if (user) {
        if (user.role !== 'admin') {
            navigate('/dashboard/app')
        }
    } else {
        navigate('/')
    }

    // ----------------- Admin Authentication end -----------------------------------------


    return (
        <>
            <Helmet>
                <title> Enrolled Users </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div className='totalComponents'>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Enrolled Users" total={totalUser} icon={'ph:users-fill'} />
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={6}>
              <AppWidget title="InActive Users" total={1} color="info" icon={'ph:users-fill'} />
            </Grid> */}
                    </div>

                    {/* <Stack direction={'row'} spacing={2}>
     
          </Stack> */}
                </Stack>

                <Card>
                    <div className='tableTop' style={{ padding: 20 }}>
                        <div hidden={!loading ? 'true' : ''}>
                            <CircularProgress color="primary" />
                        </div>
                        <div> </div>

                        <form onSubmit={searchHandler}>
                            <TextField type='text' onChange={e => setKeyword(e.target.value)} value={keyword} placeholder='Search user' size="small" label="Search user" variant="outlined" />
                        </form>


                    </div>
                    <div style={{ height: 'auto', width: '100%' }} >
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            // checkboxSelection
                            pagination={false}
                            sx={
                                {
                                    padding: '0 1vmax'
                                }
                            }
                        />
                    </div>
                    {
                        users?.length === 0 ? <EmptyCard title={'Not Enrolled! '} />
                            :
                            <div className='paginationBox' >
                                {
                                    totalUser > resultPerPage &&
                                    <Pagination
                                        activePage={page}
                                        itemsCountPerPage={resultPerPage}
                                        totalItemsCount={totalUser}
                                        pageRangeDisplayed={5}
                                        onChange={e => setPage(e)}
                                    />
                                }
                            </div>
                    }

                </Card>
            </Container>




        </>
    );
}

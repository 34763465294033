import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import './CourseStorePage.css';
// @mui
import {
    CircularProgress,
    Container,
    Stack,
    TextField,
    Typography
} from '@mui/material';

// import Sheet from '@mui/joy/Sheet';
// import Done from '@mui/icons-material/Done';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// components
// sections
import EmptyCard from '../../component/EmptyCard';
import CourseCard from '../../component/CourseCard';
import { clearError, getAvailCategory, getFilterCourse } from '../../redux/actions/courseAction';



// ----------------------------------------------------------------------

export default function CourseStorePage() {

    // const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');
    const [cat, setCat] = useState([]);

    // ------------------------ get categories ------------------------

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAvailCategory())
    }, [dispatch])

    const { loading: catLoading, categories, error: catErr } = useSelector((state) => state.getCategories)
    console.log(categories)

    useEffect(() => {
        if (catErr) {
            toast.error(catErr);
            dispatch(clearError());
        }
    }, [catErr, navigate, dispatch])


    // ------------------------ get courses ------------------------
    useEffect(() => {
        dispatch(getFilterCourse(cat, keyword))
    }, [cat, keyword])

    const { loading, courses, error: getErr } = useSelector((state) => state.getCourses)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr);
            dispatch(clearError());
        }
    }, [getErr, navigate, dispatch])




    // ----------------- Admin Authentication -----------------------------------------
    const { user } = useSelector((state) => state.users)
    if (user) {
        if (user.role !== 'user') {
            navigate('/dashboard/app')
        }
    } else {
        navigate('/')
    }

    // ----------------- Admin Authentication end -----------------------------------------
    const handleChecked = (event, item) => {

        if (!cat.includes(item)) {
            setCat((val) => [...val, item]);
        } else {
            setCat((val) => val.filter((text) => text !== item));
        }
    }
    const searchHandler = () => {

    }

    return (
        <>
            <Helmet>
                <title> Courses </title>
            </Helmet>

            <Container>
                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div className='totalComponents'>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Total Courses" total={courses?.length === 0 ? '0' : courses?.length} icon={'ph:users-fill'} />
                        </Grid>
                    </div>
                </Stack> */}

                <Stack variant="outlined" sx={{ width: '100%' }}>
                    <Typography id="rank" level="body-sm" fontWeight="lg" sx={{ mb: 1.5 }}>
                        Categories
                    </Typography>
                    <div role="group" aria-labelledby="rank">
                        <div className='catBox'>
                            {categories?.map(
                                (item, index) => (
                                    <div key={item._id}>
                                        <button
                                            className={cat.includes(item._id) ? 'checked' : 'uncheck'}
                                            onClick={e => handleChecked(e, item._id)}
                                        >
                                            {item.categoryName}
                                        </button>
                                    </div>
                                ),
                            )}
                        </div>
                    </div>
                </Stack>


                {/* <Card> */}
                <div className='tableTop'>
                    <div hidden={!loading ? 'true' : ''}>
                        <CircularProgress color="primary" />
                    </div>
                    <Typography variant="p" color="initial" my={2}> Courses</Typography>

                    <form onSubmit={searchHandler}  className='formSearch'>
                        <TextField type='text' style={{ width: '100%' }} onChange={e => setKeyword(e.target.value)} value={keyword} placeholder='Search user' size="small" label="Search Course" variant="outlined" />
                    </form>



                </div>
                {/* <Divider /> */}
                {
                    courses?.length === 0 ? <EmptyCard title={'No Course Found'} />
                        :
                        <div className="courseContainers">
                            {
                                courses && courses.map((item) => (
                                    <CourseCard item={item} />
                                ))
                            }


                        </div>
                }
                {/* <div className='paginationBox' >
                        <Pagination count={count} page={page} onChange={handlePage} color="primary" />
                    </div> */}
                {/* </Card> */}
            </Container>





        </>
    );
}

import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import './mainCoursePage.css';
// @mui
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography
} from '@mui/material';


import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { MdSettingsBackupRestore } from 'react-icons/md';
// components
import { FaEye } from 'react-icons/fa';
import Iconify from '../../components/iconify';
import { AppWidget } from '../../sections/@dashboard/app';
// sections
import ComingSoon from '../../images/imgEdu/coming-soon.jpg';
import { clearError, getAllCourse } from '../../redux/actions/courseAction';

import { getDate } from '../../utils/getDate';
import NewCourseUi from './NewCourseUi';
import EmptyCard from '../../component/EmptyCard';

// ----------------------------------------------------------------------

export default function CourseMainPage() {

  // const [page, setPage] = useState(1);
  // const [keyword, setKeyword] = useState('');

  const [course, setCourses] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [recoverModalOpen, setRecoverModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  const recoverHandle = (item) => {
    // ModalHandleOpen()
    setCourses(item)
    setRecoverModalOpen(true)

  }

  // const deleteHandle = (item) => {
  //   setCourses(item)
  //   setDeleteModalOpen(true)
  // }


  const courseModalHandleOpen = () => {
    setModalOpen(true)

  };


  // const editHandle = (item) => {
  //   setCourses(item)
  //   setUpdateModalOpen(true)
  // }

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCourse())
  }, [dispatch])

  const { loading, courses, error: getErr } = useSelector((state) => state.getCourses)
  console.log(courses)
  useEffect(() => {
    if (getErr) {
      toast.error(getErr);
      dispatch(clearError());
    }
  }, [getErr, navigate, dispatch])



  // ----------------- Admin Authentication -----------------------------------------
  const { user } = useSelector((state) => state.users)
  if (user) {
    if (user.role !== 'admin') {
      navigate('/dashboard/app')
    }
  } else {
    navigate('/')
  }

  // ----------------- Admin Authentication end -----------------------------------------

  return (
    <>
      <Helmet>
        <title> Courses </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div className='totalComponents'>
            <Grid item xs={12} sm={6} md={6}>
              <AppWidget title="Total Courses" total={courses?.length === 0 ? '0' : courses?.length} icon={'ph:users-fill'} />
            </Grid>

            {/* <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Member" total={totalMember === 0 ? '0' : totalMember} color="info" icon={'ph:users-fill'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Special Invitee" total={totalSplInviteeMembers === 0 ? '0' : totalSplInviteeMembers} icon={'ph:users-fill'} />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Zonal" total={totalZonalMembers === 0 ? '0' : totalZonalMembers} color="info" icon={'ph:users-fill'} />
                        </Grid> */}
          </div>

          {/* */}
          <Button variant="contained" onClick={courseModalHandleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Course
          </Button>
          {/* </Link> */}
        </Stack>

        {/* <Card> */}
        <div className='tableTop'>
          <div hidden={!loading ? 'true' : ''}>
            <CircularProgress color="primary" />
          </div>
          <div>courses</div>
          {/* 
                        <form onSubmit={searchHandler}>
                            <TextField type='text' onChange={e => setKeyword(e.target.value)} value={keyword} placeholder='Search user' size="small" label="Search user" variant="outlined" />
                        </form> */}
          <div> </div>


        </div>
        {/* <Divider /> */}

        {
          courses?.length === 0 ? <EmptyCard title={'No Course Found'} />
            :
            <div className="courseContainers">
              {
                courses && courses.map((item) => (
                  <div className="courseBox">

                    <div className='thumbnailBox'>
                      <img src={item.thumbnails?.url ? item.thumbnails?.url : ComingSoon} alt="" className='thumbnail' />
                    </div>

                    <div className="courseItem">
                      <h4 className='courseHeading'> <b>{item?.name}</b></h4>
                      <Typography variant="p" className='courseParagraph' color="initial" textOverflow={'hidden'}> {item?.title}</Typography>
                    </div>

                    <div className="actionBox">
                      <div className="dateBox">
                        <p className='date'>Create : {getDate(item?.createAt)}</p>
                      </div>
                      <div className="icon-Box" >
                        <p className={item.isActive ? 'Active' : 'InActive'} >{item.isActive ? 'Active' : 'InActive'}</p>
                        <div className='icons'>
                          {
                            item?.deleteAt ? (<button onClick={e => recoverHandle(item)}>
                              <MdSettingsBackupRestore />
                            </button>) : (<>
                              <Link to={`/dashboard/view-course/${item._id}`} className='icon-btn'>
                                <FaEye />
                              </Link>

                              {/* <button onClick={e => editHandle(item)} className='icon-btn'>
                    <MdModeEdit />
                  </button> */}

                              {/* <button onClick={e => deleteHandle(item)} className='icon-btn'>
                    <MdDelete />
                  </button> */}
                            </>)
                          }
                        </div>


                      </div>

                    </div>

                  </div>
                ))
              }


            </div>
        }

        {/* <div className='paginationBox' >
                        <Pagination count={count} page={page} onChange={handlePage} color="primary" />
                    </div> */}
        {/* </Card> */}
      </Container>

      <NewCourseUi modalOpen={modalOpen} setModalOpen={setModalOpen} />
      {/* <UpdateCourseUi course={course} updateModalOpen={updateModalOpen} setUpdateModalOpen={setUpdateModalOpen} />
      <RecoverCourseUi course={course} recoverModalOpen={recoverModalOpen} setRecoverModalOpen={setRecoverModalOpen} />
      <DeleteCourseUi course={course} deleteModalOpen={deleteModalOpen} setDeleteModalOpen={setDeleteModalOpen} /> */}




    </>
  );
}


import React, { useEffect,useState } from 'react';
import './PaymentStatus.css';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearError, getPayment } from '../../redux/actions/paymentsAction';

const PaymentStatus = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const { loading, payment, error } = useSelector((state) => state.getPayments)
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (error) {
      toast.error(error)
      clearError()
    }
  }, [error])

  useEffect(() => {
    if (id) dispatch(getPayment(id))
  }, [id])

  useEffect(() => {
    setStatus(payment?.payment_status)
    // console.log(payment?.payment_status)
  }, [payment])



  return (
   <>
   {
    loading ? "Please Wait, Loading..." :
    <div className="payment-status">
    {status === 'Successful' ? (
      <div className="success">
        <div className="checkmark-container">
          <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path className="checkmark__check" fill="none" d="M14 27l7.8 7.8L38 18" />
          </svg>
        </div>
        <h1>Payment Successful!</h1>
        <p>Order ID: {payment?.order_id}</p>
        <p>Name: {payment?.name}</p>
        <p>Email: {payment?.email}</p>
        <p>Phone: {payment?.phone}</p>
        <p>Amount: ₹{payment?.amount}</p>

        <div>

          <Link to={'/dashboard/course-store'}>
            <Button variant='contained'>
              Home
            </Button>
          </Link>
        </div>
      </div>
    ) : status === 'Pending' ? (
      <div className="pending">
        <h1>Payment Pending...</h1>
        <p>Your payment is currently being processed. Please wait a moment and refresh the page.</p>
      </div>
    ) : (
      <div className="error">
        <h1>Payment Failed</h1>
        <p>Unfortunately, there was an error processing your payment. Please try again.</p>
      </div>
    )}
  </div>
   }
   </>
  );
};

export default PaymentStatus;

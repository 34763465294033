import React, { useEffect } from 'react'
import './overviewUi.css'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Divider, Stack,Typography } from '@mui/material';
import { clearError, getAllFilesDetails } from '../../redux/actions/filesAction';

const OverViewUi = ({ course }) => {
    const dispatch = useDispatch();
    const { courseId } = useParams()


    const { loading: fileLoading, file, error: fileErr } = useSelector((state) => state.getFilesData)

    useEffect(() => {
        dispatch(getAllFilesDetails(courseId))
    }, [courseId])

    useEffect(() => {
        if (fileErr) {
            toast.error(fileErr);
            dispatch(clearError());
        }
    }, [fileErr])
    return (
        <Card>

       <Stack padding={{sm:2, xs:2}}>

            <h3>Overview</h3>
            {/* <img src={course?.thumbnails?.url ? course.thumbnails?.url : ComingSoon} alt="" className='banner' /> */}
            <div className="courseItem">
                <Typography variant="h5" color="primary.main">{course?.name}</Typography>
                <Typography variant="p" color="initial">{course?.title}</Typography>
                {/* <p> {const s = course?.htmls.toHtmlObject}</p> */}
                <div dangerouslySetInnerHTML={{ __html: course?.description }}  />
            </div>

            <br />
            <Divider />

            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} py={5}>
                <Stack width={'50%'} alignItems={'center'} justifyContent={'space-between'} borderRight={1} borderColor={'lightgrey'} >
                    <Typography variant="p" color="initial"> Validity </Typography>
                    <Typography variant="p" color="initial">  {course?.expired} Months</Typography>
                </Stack>

                <Divider  />


                <Stack width={'50%'} alignItems={'center'} justifyContent={'center'}>

                    <Typography variant="p" color="initial">Free Content : {file?.free}</Typography>
                    <Typography variant="p" color="initial">Videos : {file?.videos} | Files : {file?.files}</Typography>
                    {/* <Typography variant="p" color="initial"></Typography> */}

                </Stack>

            </Stack>

            </Stack>

        </Card>

    )
}

export default OverViewUi
import React, { useEffect } from 'react'
import { Divider, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearError, getCourse } from '../../redux/actions/courseAction';
import OverViewUi from './OverViewUi'
import ChapterUi from './ChapterUi'
import BuyCourseUi from './BuyCourseUi'
import { getAllChapters, getChapters } from '../../redux/actions/filesAction';

const CourseForUserPage = () => {
    const { loading, course, error: getErr } = useSelector((state) => state.get)
    // const navigate = useNavigate();
    const dispatch = useDispatch();

    const { courseId } = useParams()

    useEffect(() => {
        dispatch(getCourse(courseId))
    }, [courseId])

    useEffect(() => {
        if (getErr) {
            toast.error(getErr);
            dispatch(clearError());
        }
    }, [getErr])



    // console.log(course)

    return (
        <>
            <Stack
                direction={{ xs: 'column-reverse', sm: 'row' }}
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
            >
                <Stack
                    width={{sx:'100%', sm:'60%'}}
                    spacing={2}
                >
                    <div>
                        <OverViewUi course={course} />
                    </div>
                    <div >
                        <ChapterUi course={course} />
                    </div>

                </Stack>


                <Stack
                    width={{sx:'100%',sm:'35%'}}
                    spacing={2}
                >
                    <div >
                        <BuyCourseUi course={course} />
                    </div>
                </Stack>
            </Stack >
        </>
    )
}

export default CourseForUserPage
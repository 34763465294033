import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './CourseStorePage.css';
// @mui
import {
    Button,
    CircularProgress,
    Container, Typography
} from '@mui/material';

// import Sheet from '@mui/joy/Sheet';
// import Done from '@mui/icons-material/Done';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// components
// sections
import EmptyCard from '../../component/EmptyCard';
import ComingSoon from '../../images/imgEdu/coming-soon.jpg';
import { clearError, getEnrolledCourses } from '../../redux/actions/courseAction';



// ----------------------------------------------------------------------

export default function CourseStorePage() {

    // const [page, setPage] = useState(1);
    // const [keyword, setKeyword] = useState('');

    const { user } = useSelector((state) => state.users)

    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        if (user) dispatch(getEnrolledCourses(user?._id))
    }, [user])

    const { loading, courses, error: getErr } = useSelector((state) => state.getCourses)

    useEffect(() => {
        if (getErr) {
            toast.error(getErr);
            dispatch(clearError());
        }
    }, [getErr, navigate, dispatch])




    // ----------------- Admin Authentication -----------------------------------------
    if (user) {
        if (user.role !== 'user') {
            navigate('/dashboard/app')
        }
    } else {
        navigate('/')
    }

    // ----------------- Admin Authentication end -----------------------------------------



    return (
        <>
            <Helmet>
                <title> My Enrolled Courses </title>
            </Helmet>

            <Container>
                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div className='totalComponents'>
                        <Grid item xs={12} sm={6} md={6}>
                            <AppWidget title="Total Courses" total={courses?.length === 0 ? '0' : courses?.length} icon={'ph:users-fill'} />
                        </Grid>
                    </div>
                </Stack> */}




                {/* <Card> */}
                <div className='tableTop'>
                    <div hidden={!loading ? 'true' : ''}>
                        <CircularProgress color="primary" />
                    </div>
                    <Typography variant="p" color="initial" padding={2}>My Enrolled Courses</Typography>

                    {/* <form onSubmit={searchHandler} style={{width:'40%'}}>
                        <TextField type='text' style={{width:'100%'}} onChange={e => setKeyword(e.target.value)} value={keyword} placeholder='Search user' size="small" label="Search Course" variant="outlined" />
                    </form> */}



                </div>
                {
                    courses?.length === 0 ?
                        <EmptyCard title={'Enroll Course now'} />
                        :
                        <div className="courseContainers">
                            {
                                courses && courses.map((item) => (
                                    <div className="courseBox">

                                        <img src={item.thumbnails?.url ? item.thumbnails?.url : ComingSoon} alt="" className='thumbnail' />
                                        <div className="courseItem">
                                            <h4 className='headTag'> <b>{item?.name}</b></h4>
                                            {/* <p className='ellipsis'> {item?.title}</p> */}
                                            {/* <p> Rs {item?.price}</p> */}
                                            <div className='priceBox'>
                                                {
                                                    item?.price === 0 ?
                                                        <h4 className='freetag'>Free</h4>
                                                        :
                                                        <>
                                                            <h4>₹{item?.price - ((item?.price * item?.discount) / 100)}</h4>
                                                            <p>₹{item?.price} </p>
                                                        </>
                                                }

                                            </div>
                                        </div>

                                        <div className="actionBox">
                                            {/* <div className="dateBox">
                                        <p>Create : {item?.createAt}</p>
                                    </div> */}
                                            <Link to={`/dashboard/enrolled-course/${item._id}`} className=''>
                                                <Button fullWidth size='large' variant='contained'>View</Button>
                                            </Link>
                                        </div>

                                    </div>
                                ))
                            }


                        </div>


                }


                {/* <div className='paginationBox' >
                        <Pagination count={count} page={page} onChange={handlePage} color="primary" />
                    </div> */}
                {/* </Card> */}
            </Container>





        </>
    );
}

import { error } from "jodit/esm/core/helpers";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  REGISTER_USER_REQUEST,
  REGISTER_USER_FAIL,
  REGISTER_USER_SUCCESS,
  LOAD_USER_REQUEST,
  LOAD_USER_FAIL,
  LOAD_USER_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_RESET,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_RESET,
  UPDATE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,

  ALL_USER_FAIL,
  ALL_USER_REQUEST,
  ALL_USER_SUCCESS,

  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,

  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_RESET,
  DELETE_USER_SUCCESS,

  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_RESET,
  UPDATE_USER_SUCCESS,

  CLEAR_ERROR,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  INVITE_USER_REQUEST,
  INVITE_USER_FAIL,
  INVITE_USER_SUCCESS,
  CREATE_USER_RESET,
  FORGOT_PASSWORD_RESET,
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAIL,
  EMAIL_VERIFY_REQUEST,
  EMAIL_VERIFY_SUCCESS,
  EMAIL_VERIFY_FAIL,
  EMAIL_VERIFY_RESET,
  MEMBER_REGISTRATION_REQUEST,
  MEMBER_REGISTRATION_SUCCESS,
  MEMBER_REGISTRATION_FAIL,
  MEMBER_REGISTRATION_RESET,
  BULK_USERS_REQUEST,
  BULK_USERS_SUCCESS,
  BULK_USERS_FAIL,
  BULK_USERS_RESET,
  RENEW_REQUEST,
  RENEW_FAIL,
  RENEW_SUCCESS,
  RENEW_RESET,
  OTP_VERIFY_REQUEST,
  FIRST_LOGIN_REQUEST,
  OTP_VERIFY_SUCCESS,
  FIRST_LOGIN_SUCCESS,
  OTP_VERIFY_FAIL,
  FIRST_LOGIN_FAIL,
  LOGIN_RESET,
  ENROLLED_USERS_REQUEST,
  ENROLLED_USERS_SUCCESS,
  ENROLLED_USERS_FAIL,
} from "../constants/userConstant";

export const allUserReducers = (state = { users: [] }, action) => {
  switch (action.type) {
    case ALL_USERS_REQUEST:
      return {
        loading: true,
      };

    case ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users,
      };
    case ALL_USERS_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        users: null,
        // error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const usertReducers = (state = { user: {} }, action) => {
  switch (action.type) {
    case OTP_VERIFY_REQUEST:
    case FIRST_LOGIN_REQUEST:
    case LOAD_USER_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        success: false

      };

    case OTP_VERIFY_SUCCESS:
    case FIRST_LOGIN_SUCCESS:
    case LOAD_USER_SUCCESS:

      return {
        ...state,
        loading: false,
        isAuthenticated: action.payload.firstLogin,
        user: action.payload,
        success: true

      };



    case OTP_VERIFY_FAIL:
    case FIRST_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null,
        error: action.payload,
        success: false

      };

    case LOAD_USER_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        user: null,
        // error: action.payload,
        success: false

      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated:false,
        user:null,
        success:null
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const createUserReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
    case INVITE_USER_REQUEST:
      return {
        loading: true,
        success: false
      };

    case CREATE_USER_SUCCESS:
    case INVITE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true

      };

    case CREATE_USER_RESET:
      return {
        ...state,
        loading: false,
        success: false
      };

    case CREATE_USER_FAIL:
    case INVITE_USER_FAIL:
      return {
        ...state,
        loading: false,
        success: false
        , error: action.payload
      };


    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const profiletReducers = (state = { profile: {} }, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_REQUEST:
    case UPDATE_PASSWORD_REQUEST:
    case UPDATE_USER_REQUEST:
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_PROFILE_SUCCESS:
    case UPDATE_PASSWORD_SUCCESS:
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };


    case UPDATE_PROFILE_RESET:
    case UPDATE_PASSWORD_RESET:
    case UPDATE_USER_RESET:
      return {
        ...state,
        isUpdated: false,
      };

    case DELETE_USER_RESET:
      return {
        ...state,
        isDeleted: false,
      };

    case UPDATE_PROFILE_FAIL:
    case UPDATE_PASSWORD_FAIL:
    case UPDATE_USER_FAIL:
    case DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const forgotPasswordReducers = (state = { forget: {} }, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,

      };

    case FORGOT_PASSWORD_FAIL:
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FORGOT_PASSWORD_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        message: null,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const allUsersReducers = (state = { users: [] }, action) => {
  switch (action.type) {
    case ALL_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ALL_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users,
        totalUsers: action.payload.totalfilterUser,
        resultPerPage: action.payload.resultPerPage,
      };


    case ALL_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};


export const userDetailsReducers = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };


    case USER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
// ---------------------------------------------------------------------------

export const memberEmailVerifyReducers = (state = { user: {} }, action) => {
  switch (action.type) {
    case EMAIL_VERIFY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case EMAIL_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        success: action.payload.success,
      };


    case EMAIL_VERIFY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EMAIL_VERIFY_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        user: null,
        success: false,

      };


    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const memberRegistrationReducers = (state = { user: {} }, action) => {
  switch (action.type) {
    case MEMBER_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case MEMBER_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        user: action.payload.user,
        isAuthenticated: true,
      };


    case MEMBER_REGISTRATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isAuthenticated: false,

      };

    case MEMBER_REGISTRATION_RESET:
      return {
        ...state,
        loading: false,
        error: null,

      };


    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        success: false
      };

    default:
      return state;
  }
};

export const bulkImportReducers = (state = { user: {} }, action) => {
  switch (action.type) {
    case BULK_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case BULK_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
      };


    case BULK_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BULK_USERS_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        success: false,

      };


    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        success: false
      };

    default:
      return state;
  }
};

export const renewReducers = (state = { user: {} }, action) => {
  switch (action.type) {
    case RENEW_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case RENEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        formBody: action.payload.formbody
      };


    case RENEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RENEW_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,

      };


    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        success: false
      };

    default:
      return state;
  }
};


export const emailVerify = (state = { user: {} }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
      };

    case LOGIN_SUCCESS:

      return {
        ...state,
        loading: false,
        user: action.payload,
        success:true
      };

      case LOGIN_RESET:
      return {
        ...state,
        loading: false,
        user: null,
        success:false
      };

    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload,
      };

   
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const enrolledUsers = (state = { user: {} }, action) => {
  switch (action.type) {
    case ENROLLED_USERS_REQUEST:
      return {
        loading: true,
      };

    case ENROLLED_USERS_SUCCESS:

      return {
        ...state,
        loading: false,
        users: action.payload.users,
        resultPerPage: action.payload.resultPerPage,
        totalUser: action.payload.totalUser,
      };

      case ENROLLED_USERS_FAIL:
      return {
        ...state,
        loading: false,
        users: null,
        error:action.payload
      };

    

   
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
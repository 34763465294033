import { Button, Divider, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearError, getCourse } from '../../redux/actions/courseAction';
import ChapterUi from './ChapterUi';
import OverViewUi from './OverViewUi';
import UpdateCourseUi from './UpdateCourseUi';
import UploadVideoUi from './UploadVideoUi';

const CoursePage = () => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const { courseId } = useParams()

    const { loading, course, error: getErr } = useSelector((state) => state.get)

    useEffect(() => {
        dispatch(getCourse(courseId))
    }, [courseId])

    useEffect(() => {
        if (getErr) {
            toast.error(getErr);
            dispatch(clearError());
        }
    }, [getErr])





    // const [course, setCourses] = useState('');
    const [updateModalOpen, setUpdateModalOpen] = useState(false);


    // console.log(course)
    const editHandle = () => {
        // setCourses(item)
        setUpdateModalOpen(true)
    }

    return (
        <>
            <Stack direction={'row'} justifyContent={'flex-end'} spacing={3}>
                <Link to={`/dashboard/enrolled-users/${courseId}`} variant='contained'><Button variant='contained'>Enrolled Users</Button></Link>
                <Button onClick={e => editHandle()} variant='contained'>Edit</Button>
            </Stack>
            <br />
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
            >
                <Stack
                    width={'65%'}
                    spacing={2}
                >
                    <div>
                        <OverViewUi course={course} />
                    </div>
                    <div >
                        <ChapterUi course={course} />
                    </div>

                </Stack>


                <Stack
                    width={'35%'}
                    spacing={2}
                >
                    <div >
                        <UploadVideoUi course={course} />
                    </div>
                </Stack>
            </Stack>

            <UpdateCourseUi course={course} updateModalOpen={updateModalOpen} setUpdateModalOpen={setUpdateModalOpen} />

        </>
    )
}

export default CoursePage
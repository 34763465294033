import { FaHelicopter, FaQuestion } from 'react-icons/fa';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, CircularProgress, List, ListItemText, Modal, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
// import data from '../../layouts/dashboard/nav/config';
// ----------------------------------------------------------------------
// Nav Config Data 


import event from '../../images/icons/ic_event.png';
import news from '../../images/icons/news-177.svg';
import directory from '../../images/icons/new-directory.svg';
import team from '../../images/icons/team.svg';
import gallery from '../../images/icons/gallery.png';
import magazine from '../../images/icons/magazine.png';
import newspaper from '../../images/icons/newspaper.png';
import booking from '../../images/icons/booking-history.png';
import users from '../../images/icons/users-m.svg';
import analitics from '../../images/icons/analytics.svg';
import orgs from '../../images/icons/orgs.svg';

// component
import SvgColor from '../svg-color';
// import { queryAction } from '../../redux/actions/contactAction';
// import { CREATE_QUERY_RESET } from '../../redux/constants/contactConstant';
// import { clearError } from '../../redux/actions/userAction';
// import { da } from 'date-fns/locale';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={name} sx={{ width: 1, height: 1 }} />;


NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ dat = [], ...other }) {

  const { user } = useSelector((state) => state.users)
  // console.log(user)
  let data = [

    (user?.role === 'admin') ?
      {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: icon('/assets/icons/navbar/ic_analytics.svg'),

      } : '',
      (user?.role === 'user') ?
    {
      title: 'COURSE STORE',
      path: '/dashboard/course-store',
      icon: icon(event),
    }:'',
    (user?.role === 'user') ?
    {
      title: 'MY ENROLLED',
      path: '/dashboard/enrolled-course',
      icon: icon(users),
    } : '',

    (user?.role === 'admin') ?
    {
      title: 'USER',
      path: '/dashboard/user',
      icon: icon(users),
    }:'',
    
    (user?.role === 'user') ?
    {
      title: 'PAYMENTS',
      path: '/dashboard/payments',
      icon: icon(booking),
    }:'',

    (user?.role === 'admin') ?
    {
      title: 'COURSE ADMIN',
      path: '/dashboard/course',
      icon: icon(analitics),
    }:'',
    (user?.role === 'admin') ?
    {
      title: 'CATEGORY',
      path: '/dashboard/category',
      icon: icon(users),
    }:'',


  ]

  // console.log(data)
  data = data.filter((a) => a !== '');
  // console.log(data)
  // help desk Modal --------------------------------------------- 



  const Navigate = useNavigate();
  const dispatch = useDispatch();




  return (
    <>
      <Box {...other}>
        <List disablePadding sx={{ p: 1 }}>
          {data.map((item) => (
            <NavItem key={item.title} item={item} />
          ))}


        </List>
      </Box>

      {/* Modal */}
      {/* <div>
        <Modal
          open={openHelp}
          onClose={handleCloseHelp}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box id='modalBox'>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Help Desk
            </Typography>

            <div className='helpFormBox'>
              <form action="" onSubmit={queryHandler}>
                <input type="text" placeholder='Name' required name="" id="" value={nameHelp} onChange={e => setNameHelp(e.target.value)} />
                <input type="email" placeholder='Email' required name="" id="" value={emailHelp} onChange={e => setEmailHelp(e.target.value)} />
                <input type="number" placeholder='Phone ' required name="" id="" value={phoneHelp} onChange={e => setPhoneHelp(e.target.value)} />
                <textarea className='textArea' name="" required placeholder='Query' id="" value={queryHelp} onChange={e => setQueryHelp(e.target.value)} cols="20" rows="5" />

                {
                  Helploading ? <div style={{ textAlign: 'center' }}> <CircularProgress color="success" /></div> :
                    <Button type='submit' variant="contained" placeholder='Send'   >Send</Button>
                }

              </form>

            </div>

          </Box>
        </Modal>
      </div> */}
    </>

  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}

import axios from "axios";

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_FAIL,
  LOAD_USER_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  ALL_USER_FAIL,
  ALL_USER_REQUEST,
  ALL_USER_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  CLEAR_ERROR,
  INVITE_USER_REQUEST,
  INVITE_USER_FAIL,
  INVITE_USER_SUCCESS,
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAIL,
  EMAIL_VERIFY_REQUEST,
  EMAIL_VERIFY_SUCCESS,
  EMAIL_VERIFY_FAIL,
  BULK_USERS_REQUEST,
  BULK_USERS_SUCCESS,
  BULK_USERS_FAIL,
  MEMBER_REGISTRATION_REQUEST,
  MEMBER_REGISTRATION_SUCCESS,
  MEMBER_REGISTRATION_FAIL,
  RENEW_REQUEST,
  RENEW_SUCCESS,
  RENEW_FAIL,
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_FAIL,
  FIRST_LOGIN_REQUEST,
  FIRST_LOGIN_SUCCESS,
  FIRST_LOGIN_FAIL,
  ENROLLED_USERS_REQUEST,
  ENROLLED_USERS_SUCCESS,
  ENROLLED_USERS_FAIL,
} from "../constants/userConstant";
import {host} from "../../utils/config";
// import { WELCOME_NOTIFICATION_FAIL, WELCOME_NOTIFICATION_REQUEST, WELCOME_NOTIFICATION_SUCCESS } from "../actions/newsLetterAction";


// renew and payment
export const renewMember = (userID, useData) => async (dispatch) => {
  try {
    dispatch({ type: RENEW_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };
    const data = await axios.put(`${host}/user/api/v1/renew/${userID}`, useData, config);
    dispatch({ type: RENEW_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: RENEW_FAIL,
      payload:error.response.data.message,
    });
  }
};

// Login
export const login = (email) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };
    const data = await axios.post(`${host}/user/api/v1/login`, { email }, config);
    dispatch({ type: LOGIN_SUCCESS, payload: data.data.user });
  } catch (error) {
    dispatch({ type: LOGIN_FAIL, payload:error.response.data.message });
  }
};


export const otpVerification = (details) => async (dispatch) => {
  try {
    dispatch({ type: OTP_VERIFY_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };
    const data = await axios.put(`${host}/user/api/v1/otp`, details, config);
    dispatch({ type: OTP_VERIFY_SUCCESS, payload: data.data.user });
  } catch (error) {
    dispatch({ type: OTP_VERIFY_FAIL, payload:error.response.data.message });
  }
};

export const firstLogin = (details) => async (dispatch) => {
  try {
    dispatch({ type: FIRST_LOGIN_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };
    const data = await axios.put(`${host}/user/api/v1/f-login`, details, config);
    dispatch({ type: FIRST_LOGIN_SUCCESS, payload: data.data.user });
  } catch (error) {
    dispatch({ type: FIRST_LOGIN_FAIL, payload:error.response.data.message });
  }
};

// register
export const register = (useData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };
    const data = await axios.post(`${host}/user/api/v1/register`, useData, config);
    dispatch({ type: REGISTER_USER_SUCCESS, payload: data.data.user });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload:error.response.data.message,
    });
  }
};
// create user by admin / other
// export const welcomeNotification = (useData) => async (dispatch) => {
//   try {
//     dispatch({ type: WELCOME_NOTIFICATION_REQUEST });
//     const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };
//     const data = await axios.post(`${host}/user/api/v1/welcome/notification`, useData, config);
//     dispatch({ type: WELCOME_NOTIFICATION_SUCCESS, payload: data.data.user });
//     // console.log(data.user)
//   } catch (error) {
//     dispatch({
//       type: WELCOME_NOTIFICATION_FAIL,
//       payload:error.response.data.message,
//     });
//   }
// };

// create user by admin / other
export const createUser = (useData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_USER_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };
    const data = await axios.post(`${host}/user/api/v1/create/user`, useData, config);
    dispatch({ type: CREATE_USER_SUCCESS, payload: data.data.user });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: CREATE_USER_FAIL,
      payload:error.response.data.message,
    });
  }
};

// create user by admin / other
export const inviteUser = (useData,token) => async (dispatch) => {
  try {
    dispatch({ type: INVITE_USER_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };
    const data = await axios.post(`${host}/user/api/v1/invite/user/${token}`, useData, config);
    dispatch({ type: INVITE_USER_SUCCESS, payload: data.data.user });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: INVITE_USER_FAIL,
      payload:error.response.data.message,
    });
  }
};

// load user
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };


    const data = await axios.get(`${host}/user/api/v1/me`, config);
    dispatch({ type: LOAD_USER_SUCCESS, payload: data.data.user });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: LOAD_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// user for executive committee
export const allUsers = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_USERS_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };


    const data = await axios.get(`${host}/user/api/v1/all-users`, config);
    dispatch({ type: ALL_USERS_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: ALL_USERS_FAIL,
      payload: error.response.data.message,
    });
  }
};


// logout user
export const logout = () => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };

    await axios.get(`${host}/user/api/v1/logout`,config);
    dispatch({ type: LOGOUT_SUCCESS });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: LOGOUT_FAIL,
      payload:error.response.data.message,
    });
  }
};

// Update profile
export const updateProfile = (useData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST });

    const config = { headers: { "Content-Type": "multipart/form-data" }, withCredentials:true };

    // console.log("6+...",)
    const data = await axios.put(`${host}/user/api/v1/profile/update`, useData, config);

    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data.data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Password
export const updatePassword = (passwords) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });

    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };

    console.log("7+...");
    const data = await axios.put(`${host}/user/api/v1/password/update`, passwords, config);

    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data.data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Forget Password
export const forgetPassword = (email) => async (dispatch) => {
  try {

    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };
    
    const data = await axios.post(`${host}/user/api/v1/password/forget`, {email}, config);

    dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data.data.massage });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload:error.response.data.message,
    });
  }
};

export const resetPassword = (token, passwords) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };
    const data = await axios.put(
      `${host}/user/api/v1/password/reset/${token}`,
      passwords,
      config
    );
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data.data.success });
    // console.log(data.data.message )
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload:error.response.data.message,
    });
  }
};

// get All  users
export const getAllUsers = (keyword,page) => async (dispatch) => {
  try {
    dispatch({ type: ALL_USER_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };

    const { data } = await axios.get(`${host}/user/api/v1/admin/users?page=${page}&keyword=${keyword}`, config);
    // console.log(`${host}/user/api/v1/admin/users?page=${page}&?keyword=${keyword}`)

    dispatch({
      type: ALL_USER_SUCCESS,
      payload: data,
    });
    // console.log(data)
  } catch (error) {
    dispatch({
      type: ALL_USER_FAIL,
      payload:error.response.data.message,
    });
  }
};

// get   users details
export const getUserDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };


    const { data } = await axios.get(`${host}/user/api/v1/admin/user/${id}`,config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data.user,
    });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:error.response.data.message,
    });
  }
};

// update user
export const updateUser = (id,userData) => async (dispatch) => {
  console.log(userData)
  try {
    dispatch({ type: UPDATE_USER_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };

    const { data } = await axios.put(`${host}/user/api/v1/admin/user/${id}`,userData, config);

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data.success,
    });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload:error.response.data.message,
    });
  }
};

// delete user
export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_USER_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };

    const { data } = await axios.delete(`${host}/user/api/v1/admin/user/${id}` ,config);

    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: data.success,
    });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload:error.response.data.message,
    });
  }
};

export const memberEmailVerify = (useData) => async (dispatch) => {
  try {
    dispatch({ type: EMAIL_VERIFY_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };
    const data = await axios.put(`${host}/user/api/v1/email-verify`, useData, config);
    dispatch({ type: EMAIL_VERIFY_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: EMAIL_VERIFY_FAIL,
      payload:error.response.data.message,
    });
  }
};
export const bulkImportMember = (useData) => async (dispatch) => {
  try {
    dispatch({ type: BULK_USERS_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };
    const data = await axios.post(`${host}/user/api/v1/bulk-import-user`, useData, config);
    dispatch({ type: BULK_USERS_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: BULK_USERS_FAIL,
      payload:error.response.data.message,
    });
  }
};

export const memberFirstRegistration = (useData) => async (dispatch) => {
  console.log(useData)
  try {
    dispatch({ type:MEMBER_REGISTRATION_REQUEST  });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };
    const data = await axios.put(`${host}/user/api/v1/registration`, useData, config);
    dispatch({ type: MEMBER_REGISTRATION_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: MEMBER_REGISTRATION_FAIL,
      payload:error.response.data.message,
    });
  }
};

export const getAllEnrolledUser = (courseId,page,keyword) => async (dispatch) => {
  try {
    dispatch({ type:ENROLLED_USERS_REQUEST  });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials:true };
    const data = await axios.get(`${host}/user/api/v1/enrol-users/${courseId}?page=${page}&keyword=${keyword}`, config);
    dispatch({ type: ENROLLED_USERS_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: ENROLLED_USERS_FAIL,
      payload:error?.response?.data.message,
    });
  }
};
// clearing error
export const clearError = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};

import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadUser } from '../redux/actions/userAction';
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../images/imgEdu/login.avif';
// import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
import FirstLoginPage from '../sections/auth/FirstLoginPage';
import OtpVerifyPage from '../sections/auth/OtpVerifyPage';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  const [user, setUser] = useState()
  const [screen, setScreen] = useState(0)

  const { success } = useSelector((state) => state.email)
  const { isAuthenticated, success: meSuccess } = useSelector((state) => state.users)
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(loadUser())


  }, [isAuthenticated])

  useEffect(() => {
    if (isAuthenticated) {
      navigate(user?.role === 'admin' ? '/dashboard/app' : 'dashboard/course-store')
    }


  }, [isAuthenticated, meSuccess])




  return (
    <>
      <Helmet>
        <title> Login  </title>
      </Helmet>

      <StyledRoot id='bg'>


        {mdUp && (
          <div className='logInTopBox' style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
            {/* <Typography variant="h3" sx={{ px: 5, mt: 0, mb: 5, textAlign: 'center' }}>
              Hi, Welcome Back
            </Typography> */}
            <img src={Logo} alt="login" width={'80%'} style={{ margin: '1vmax auto' }} />
          </div>
        )}

        <Container >
          <StyledContent>
            <Typography variant="h2" sx={{ my: 0 }} color={'skyblue'} gutterBottom>
              Welcome Back!
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }} color={'grey'} gutterBottom>
            Please Log In to Continue
            </Typography>

            {
              !success &&
              <LoginForm user={user} setUser={setUser} />
            }
            {
              (success && !meSuccess) &&
              <OtpVerifyPage user={user} setUser={setUser} />
            }
            {
              (meSuccess && !user?.FirstLogin) &&
              <FirstLoginPage user={user} setUser={setUser} />
            }


          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

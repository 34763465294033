import { Button, Card, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { MdError } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { clearError, getCourse } from '../../redux/actions/courseAction'
import { getDate } from '../../utils/getDate'
import ChapterFileUi from './ChapterFileUi'
import FileUi from './FileUi'
import './index.css'

const EnrolledCourseIdPage = () => {

    const { loading, course, enroll, error: getErr } = useSelector((state) => state.get)
    // const navigate = useNavigate();
    const dispatch = useDispatch();

    const { courseId } = useParams()

    useEffect(() => {
        dispatch(getCourse(courseId))
    }, [courseId])

    useEffect(() => {
        if (getErr) {
            toast.error(getErr);
            dispatch(clearError());
        }
    }, [getErr])

    const [file, setFile] = useState('')

    console.log(enroll)
    return (
        <div>
            {
                
                enroll?.isExpired ? <Stack>
                    <Card>
                        <Stack spacing={2} alignItems={'center'} justifyContent={'center'} padding={10}>
                            <MdError size={50} color='red'/>
                            <Typography variant="h3" color="initial">Course Expired !</Typography>
                            <Typography variant="p" color="initial">{getDate(enroll?.expired)}</Typography>
                            <Link to={`/dashboard/course/u/${courseId}`}>
                                <Button variant='contained' size='large'>
                                    Course
                                </Button>
                            </Link>
                        </Stack>
                    </Card>
                </Stack> :
                    <Stack spacing={5} direction={'row'} className='enrolledBox'>
                        

                        <Stack width={'70%'} className='enrollItem1'>
                            <Card>
                                <div>
                                    <FileUi file={file} />
                                </div>
                            </Card>
                        </Stack>
                        <Stack width={'30%'} className='enrollItem2'>
                            <Card>
                                <div>
                                    <ChapterFileUi course={course} setFile={setFile} file={file} />
                                </div>
                            </Card>
                        </Stack>

                    </Stack>
            }

        </div>
    )
}

export default EnrolledCourseIdPage
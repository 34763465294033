import React, { useEffect, useState } from 'react'
import './chapterMain.css'
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowLeft, FaArrowRight, FaFolder } from 'react-icons/fa';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { MdFileOpen, MdFolder, MdSettings, MdVideoFile } from 'react-icons/md';
import { clearError, getAllChapters, getAllChaptersFile, getFilesForAdmin } from '../../redux/actions/filesAction';
import NewChapterUi from './NewChapterUi'
import UpdateChapterUi from './UpdateChapterUi';
import FileUpdateUi from './FileUpdateUi';
import Iconify from '../../components/iconify';
import FileUi from '../store/FileUi';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  // height: '90vh',
  overFlow: 'auto',
  borderRadius: '0.4rem',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};


const ChapterUi = ({ course }) => {
  const dispatch = useDispatch();

  const { loading: chapLoading, chapters, error: chapErr } = useSelector((state) => state.getChapter)

  useEffect(() => {
    if (course) {
      dispatch(getAllChapters(course?._id))
    }
    if (chapErr) {
      toast.error(chapErr)
      clearError()
    }
    setChapterId('')
    // chapter()
  }, [course])


  const [chapter, setChapter] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [isToggle, setIsToggle] = useState(true);

  const [fileData, setFileDate] = useState(false);
  const [fileModalOpen, setFileModalOpen] = useState(false);

  const ModalHandleOpen = () => {
    setModalOpen(true)
  };


  const editHandle = (item) => {
    setChapter(item)
    setUpdateModalOpen(true)
  }

  // ------------------------- get files ---------------------
  const handleChapterId = (chapt) => {
    setChapterId(chapt)
    setIsToggle(!isToggle)
  }
  const { loading: fileLoading, files, error: fileErr } = useSelector((state) => state.getFiles)
  const [chapterId, setChapterId] = useState('')

  useEffect(() => {
    if (fileErr) {
      toast.error(fileErr)
      clearError()
    }
  }, [fileErr])

  useEffect(() => {
    dispatch(getFilesForAdmin(chapterId?._id))
  }, [chapterId])

  // console.log(files)



  const [file, setFile] = useState('')
  const [viewModalOpen, setViewModalOpen] = useState(false)

  const handleView = (file) => {
    setFile(file)
    vModalToggle()
  }

  const vModalToggle = () => {
    setViewModalOpen(!viewModalOpen);
  }

  const fileUpdateHandle = (file) => {
    // alert(file.fileName)
    console.log(file?.isActive)
    setFileDate(file)
    setFileModalOpen(!fileModalOpen)
  }



  return (
    <div className='chapterMain'>

      {
        isToggle ?
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <h3>Content</h3>
              <Button variant="contained" onClick={ModalHandleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                New Chapter
              </Button>
            </Stack>

            {
              chapters?.length === 0 ?
                <div className='noChapter'>
                  <MdFolder /> Empty Chapter
                </div>
                :
                <div className='chaptersBox'>
                  <Stack justifyContent="space-between" >

                    {
                      chapters && chapters.map((item, i) => (
                        <div key={i} className={files && (item._id === files[0]?.chapterId ? 'chapterItem chapterActive' : 'chapterItem')}>
                          <Stack direction={'row'} alignItems={'center'} gap={2}>
                            <FaFolder size={50} className='svg' />
                            <Typography variant="p" color="initial">{item.name}</Typography>
                            <div className={item.isActive ? 'active activeWidth' : 'inActive activeWidth'}> {item?.isActive ? 'Active' : 'InActive'}</div>
                          </Stack>

                          <Stack direction={'row'} alignItems={'center'} gap={1}>
                            <Button onClick={e => editHandle(item)} className='bt n' size='large' variant='outlined'>
                              <MdSettings size={20} />
                            </Button>
                            <Button onClick={e => handleChapterId(item)} className='bt n' size='large' variant='outlined'>
                              <FaArrowRight size={15} />
                            </Button>
                          </Stack>

                        </div>
                      ))
                    }
                  </Stack>

                </div>
            }

          </> :
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" paddingY={1}>
              <Button variant="outlined" onClick={e => setIsToggle(!isToggle)} size='large'>
                <FaArrowLeft />
              </Button>
              {/* <h3>Content</h3> */}
              <h4>{chapterId?.name}</h4>

            </Stack>
            <Stack direction="row" justifyContent="space-between">
              {
                files?.length === 0 ?
                  <div className='nofiles'>
                    <MdFolder /> Empty file
                  </div>
                  : <div className='filesBox'>
                    {
                      fileLoading ? "Loading..." : ''
                    }
                    {
                      files?.map(item => (

                        <Stack direction={'row'} width={'100%'}>
                          <Button className='fileItem' onClick={e => handleView(item)}>
                            <MdVideoFile size={20} />
                            {/* <a rel="noreferrer" target='_blank' href={item?.file?.url} ></a> */}
                            <Typography variant="p" color="initial">{item.fileName ? item.fileName : 'Unknown'}</Typography>
                          </Button>
                          <div className='activeBox'>
                            <div className={item.isActive ? 'active activeWidth' : 'inActive activeWidth'}> {item?.isActive ? 'Active' : 'InActive'}</div>
                          </div>

                          <Button onClick={e => fileUpdateHandle(item)}><MdSettings /></Button>
                        </Stack>
                      ))
                    }
                  </div>
              }

            </Stack>

          </>

      }










      <NewChapterUi setModalOpen={setModalOpen} course={course} modalOpen={modalOpen} />
      <FileUpdateUi setFileModalOpen={setFileModalOpen} file={fileData} fileModalOpen={fileModalOpen} />
      <UpdateChapterUi chapter={chapter} setUpdateModalOpen={setUpdateModalOpen} updateModalOpen={updateModalOpen} />

      <Modal
        open={viewModalOpen}
        // onClose={MemberModalHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style} className='bgb'>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <div> </div>
            <Button onClick={e => vModalToggle()} style={{ color: 'white' }} variant="outlined" >close</Button>
          </Stack>

          <FileUi file={file} />
        </Box>
      </Modal>

    </div>
  )
}

export default ChapterUi
import {
    DELETE_COURSE_REQUEST,
    DELETE_COURSE_SUCCESS,
    DELETE_COURSE_FAIL,

    RECOVER_COURSE_REQUEST,
    RECOVER_COURSE_SUCCESS,
    RECOVER_COURSE_FAIL,

    CLEAR_ERROR,

    CREATE_COURSE_REQUEST,
    CREATE_COURSE_SUCCESS,
    CREATE_COURSE_FAIL,

    GET_ALL_COURSE_REQUEST,
    GET_ALL_COURSE_SUCCESS,
    GET_ALL_COURSE_FAIL,

    GET_COURSE_REQUEST,
    GET_COURSE_SUCCESS,
    GET_COURSE_FAIL,

    GET_FILTER_COURSE_REQUEST,
    GET_FILTER_COURSE_SUCCESS,
    GET_FILTER_COURSE_FAIL,

    UPDATE_COURSE_SUCCESS,
    UPDATE_COURSE_REQUEST,
    UPDATE_COURSE_FAIL,

    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAIL,

    GET_ALL_CATEGORY_REQUEST,
    GET_ALL_CATEGORY_SUCCESS,
    GET_ALL_CATEGORY_FAIL,

    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,

    GET_AVAIL_CATEGORY_REQUEST,
    GET_AVAIL_CATEGORY_FAIL,
    GET_AVAIL_CATEGORY_SUCCESS,

    GET_COURSE_CATEGORY_REQUEST,
    GET_COURSE_CATEGORY_SUCCESS,
    GET_COURSE_CATEGORY_FAIL,

    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAIL,

    UPDATE_COURSE_RESET,
    CREATE_COURSE_RESET,
    RECOVER_COURSE_RESET,
    DELETE_COURSE_RESET,
    UPDATE_CATEGORY_RESET,
    CREATE_CATEGORY_RESET,
    GET_ENROLL_COURSE_REQUEST,
    GET_ENROLL_COURSE_SUCCESS,
    GET_ENROLL_COURSE_FAIL,
    ENROLL_COURSE_RESET,
    ENROLL_COURSE_FAIL,
    ENROLL_COURSE_SUCCESS,
    ENROLL_COURSE_REQUEST,
    IS_ENROLL_REQUEST,
    IS_ENROLL_SUCCESS,
    IS_ENROLL_FAIL,
    GET_COURSE_DASHBOARD_REQUEST,
    GET_COURSE_DASHBOARD_SUCCESS,
    GET_COURSE_DASHBOARD_FAIL,
} from '../constants/courseConstant'


export const actionCategory = (state = { data: {} }, action) => {
    switch (action.type) {

        case UPDATE_CATEGORY_REQUEST:
        case CREATE_CATEGORY_REQUEST:
            return {
                loading: true,
                success: false,
            };

        case UPDATE_CATEGORY_SUCCESS:
        case CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };


        case UPDATE_CATEGORY_FAIL:
        case CREATE_CATEGORY_FAIL:

            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case UPDATE_CATEGORY_RESET:
        case CREATE_CATEGORY_RESET:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};
export const getCategory = (state = { data: {} }, action) => {
    switch (action.type) {
        case GET_CATEGORY_REQUEST:
            return {
                loading: true,
            };

        case GET_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                category: action.payload.category,
            };


        case GET_CATEGORY_FAIL:
            return {
                ...state,
                loading: false,
                members: null,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const getCategories = (state = { data: {} }, action) => {
    switch (action.type) {
        case GET_ALL_CATEGORY_REQUEST:
        case GET_AVAIL_CATEGORY_REQUEST:
        case GET_COURSE_CATEGORY_REQUEST:
        case GET_COURSE_DASHBOARD_REQUEST:
            return {
                loading: true,
            };

        case GET_ALL_CATEGORY_SUCCESS:
        case GET_AVAIL_CATEGORY_SUCCESS:
        case GET_COURSE_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                categories: action.payload.categories,
            };

        case GET_COURSE_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                enrollData: action.payload.enrollData,
                totalCourses: action.payload.totalCourses,
                totalEnroll: action.payload.totalEnroll,
            };

        case GET_ALL_CATEGORY_FAIL:
        case GET_AVAIL_CATEGORY_FAIL:
        case GET_COURSE_CATEGORY_FAIL:
        case GET_COURSE_DASHBOARD_FAIL:

            return {
                ...state,
                loading: false,
                members: null,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

// course

export const actionCourse = (state = { data: {} }, action) => {
    switch (action.type) {

        case UPDATE_COURSE_REQUEST:
        case CREATE_COURSE_REQUEST:
        case DELETE_COURSE_REQUEST:
        case RECOVER_COURSE_REQUEST:
            return {
                loading: true,
                success: false,
            };

        case UPDATE_COURSE_SUCCESS:
        case CREATE_COURSE_SUCCESS:
        case DELETE_COURSE_SUCCESS:
        case RECOVER_COURSE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };


        case UPDATE_COURSE_FAIL:
        case CREATE_COURSE_FAIL:
        case DELETE_COURSE_FAIL:
        case RECOVER_COURSE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case UPDATE_COURSE_RESET:
        case CREATE_COURSE_RESET:
        case RECOVER_COURSE_RESET:
        case DELETE_COURSE_RESET:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};
export const actionEnroll = (state = { data: {} }, action) => {
    switch (action.type) {

        case ENROLL_COURSE_REQUEST:
            return {
                loading: true,
                success: false,
            };

        case ENROLL_COURSE_SUCCESS:

            return {
                ...state,
                loading: false,
                success: true
            };


        case ENROLL_COURSE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case ENROLL_COURSE_RESET:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const getCourse = (state = { data: {} }, action) => {
    switch (action.type) {
        case GET_COURSE_REQUEST:
            return {
                loading: true,
            };

        case GET_COURSE_SUCCESS:
            return {
                ...state,
                loading: false,
                course: action.payload.course,
                enroll: action.payload.enroll,

            };


        case GET_COURSE_FAIL:
            return {
                ...state,
                loading: false,
                course: null,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const getCourses = (state = { data: {} }, action) => {
    switch (action.type) {
        case GET_ALL_COURSE_REQUEST:
        case GET_FILTER_COURSE_REQUEST:
        case GET_ENROLL_COURSE_REQUEST:
            return {
                loading: true,
            };

        case GET_ALL_COURSE_SUCCESS:
        case GET_FILTER_COURSE_SUCCESS:
        case GET_ENROLL_COURSE_SUCCESS:
            return {
                ...state,
                loading: false,
                courses: action.payload.courses,
            };


        case GET_ALL_COURSE_FAIL:
        case GET_FILTER_COURSE_FAIL:
        case GET_ENROLL_COURSE_FAIL:
            return {
                ...state,
                loading: false,
                members: null,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};


export const isEnrollCourse = (state = { data: {} }, action) => {
    switch (action.type) {
        case IS_ENROLL_REQUEST:
            return {
                loading: true,
            };

        case IS_ENROLL_SUCCESS:
            return {
                ...state,
                loading: false,
                isEnrolled: action.payload.success,
            };


        case IS_ENROLL_FAIL:
            return {
                ...state,
                loading: false,
                isEnrollCourse: null,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

import {


    CLEAR_ERROR,



    GET_FILE_REQUEST,
    GET_FILE_SUCCESS,
    GET_FILE_FAIL,



    CREATE_CHAPTER_REQUEST,
    CREATE_CHAPTER_SUCCESS,
    CREATE_CHAPTER_FAIL,

    GET_ALL_CHAPTER_REQUEST,
    GET_ALL_CHAPTER_SUCCESS,
    GET_ALL_CHAPTER_FAIL,

    GET_CHAPTER_REQUEST,
    GET_CHAPTER_SUCCESS,
    GET_CHAPTER_FAIL,



    UPDATE_CHAPTER_REQUEST,
    UPDATE_CHAPTER_SUCCESS,
    UPDATE_CHAPTER_FAIL,

    UPDATE_CHAPTER_RESET,
    CREATE_CHAPTER_RESET,

    TRACK_FILE_REQUEST,
    UPLOAD_FILES_REQUEST,
    TRACK_FILE_SUCCESS,
    UPLOAD_FILES_SUCCESS,

    TRACK_FILE_FAIL,
    UPLOAD_FILES_FAIL,
    TRACK_FILE_RESET,
    UPLOAD_FILES_RESET,

    GET_CHAPTER_FILES_REQUEST,
    GET_CHAPTER_FILES_SUCCESS,
    GET_CHAPTER_FILES_FAIL,
    GET_PAID_CHAPTER_FILES_REQUEST,
    GET_PAID_CHAPTER_FILES_SUCCESS,
    GET_PAID_CHAPTER_FILES_FAIL,
    GET_FILES_DETAILS_REQUEST,
    GET_FILES_DETAILS_SUCCESS,
    GET_FILES_DETAILS_FAIL,
    UPDATE_FILE_REQUEST,
    UPDATE_FILE_SUCCESS,
    UPDATE_FILE_FAIL,
    UPDATE_FILE_RESET,
    GET_FILES_ADMIN_REQUEST,
    GET_FILES_ADMIN_SUCCESS,
    GET_FILES_ADMIN_FAIL,
} from '../constants/filesConstant'


export const actionChapter = (state = { data: {} }, action) => {
    switch (action.type) {

        case UPDATE_CHAPTER_REQUEST:
        case CREATE_CHAPTER_REQUEST:
            return {
                loading: true,
                success: false,
            };

        case UPDATE_CHAPTER_SUCCESS:
        case CREATE_CHAPTER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };


        case UPDATE_CHAPTER_FAIL:
        case CREATE_CHAPTER_FAIL:

            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case UPDATE_CHAPTER_RESET:
        case CREATE_CHAPTER_RESET:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};
export const getChapter = (state = { data: {} }, action) => {
    switch (action.type) {
        case GET_CHAPTER_REQUEST:
        case GET_ALL_CHAPTER_REQUEST:
            return {
                loading: true,
            };

        case GET_CHAPTER_SUCCESS:
        case GET_ALL_CHAPTER_SUCCESS:
            return {
                ...state,
                loading: false,
                chapters: action.payload.chapters,
            };


        case GET_CHAPTER_FAIL:
        case GET_ALL_CHAPTER_FAIL:
            return {
                ...state,
                loading: false,
                chapter: null,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};


// course

export const actionFile = (state = { data: {} }, action) => {
    switch (action.type) {

        case TRACK_FILE_REQUEST:
        case UPLOAD_FILES_REQUEST:
        case UPDATE_FILE_REQUEST:
            return {
                loading: true,
                success: false,
            };

        case TRACK_FILE_SUCCESS:
        case UPLOAD_FILES_SUCCESS:
        case UPDATE_FILE_SUCCESS:

            return {
                ...state,
                loading: false,
                success: true
            };


        case TRACK_FILE_FAIL:
        case UPLOAD_FILES_FAIL:
        case UPDATE_FILE_FAIL:
            case UPDATE_FILE_RESET:

            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case TRACK_FILE_RESET:
        case UPLOAD_FILES_RESET:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const getFile = (state = { data: {} }, action) => {
    switch (action.type) {
        case GET_FILE_REQUEST:
            return {
                loading: true,
            };

        case GET_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                file: action.payload.file,
            };


        case GET_FILE_FAIL:
            return {
                ...state,
                loading: false,
                course: null,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const getFiles = (state = { data: {} }, action) => {
    switch (action.type) {
        case GET_CHAPTER_FILES_REQUEST:
        case GET_PAID_CHAPTER_FILES_REQUEST:
            case GET_FILES_ADMIN_REQUEST:

            return {
                loading: true,
            };

        case GET_CHAPTER_FILES_SUCCESS:
        case GET_PAID_CHAPTER_FILES_SUCCESS:
            case GET_FILES_ADMIN_SUCCESS:

            return {
                ...state,
                loading: false,
                files: action.payload.files,
            };


        case GET_CHAPTER_FILES_FAIL:
        case GET_PAID_CHAPTER_FILES_FAIL:
            case GET_FILES_ADMIN_FAIL:


            return {
                ...state,
                loading: false,
                members: null,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const getFilesData = (state = { data: {} }, action) => {
    switch (action.type) {
        case GET_FILES_DETAILS_REQUEST:
            return {
                loading: true,
            };

        case GET_FILES_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                file: action.payload.file,
                resultPerPage: action.payload.resultPerPage,
                totalUser: action.payload.totalUser,
            };


        case GET_FILES_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                file: null,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};
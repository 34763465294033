
// export {localX as default}
// export {localX as exportedX};

const port = window.location.hostname
// console.log(port)

if (port === '195.35.6.133') {
    exports.host = "http://195.35.6.133:7100"
}
else if (port === 'localhost') {
    exports.host = "http://localhost:7100";
}
else if (port === 'teach.designavenue.co.in') {
    exports.host = "https://app.designavenue.co.in"
}
else {
    exports.host = "https://app.designavenue.co.in"
}






//  host = "http://157.245.99.110:5409"
//   host = "http://portal.eoais.com"
//  host = "http://localhost:5409";

// export default host
// console.log(this.host)


// import React from 'react';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// const LineChartComponent = ({ data }) => {
//     return (
//         <div style={{width:'2000px', overflow:'auto'}}>

//             <ResponsiveContainer width="100%" height={500}>
//                 <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
//                     <CartesianGrid strokeDasharray="3 3" />
//                     <XAxis dataKey="name" />
//                     <YAxis  />
//                     <Tooltip />
//                     <Legend />
//                     <Line type="monotone" dataKey="enrollCount" stroke="#8884d8" />
//                 </LineChart>
//             </ResponsiveContainer>
//         </div>

//     );
// };

// export default LineChartComponent;

import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Typography from '@mui/material/Typography'

const BarChartComponent = ({ data }) => {
    return (
        <div style={{ width: '100%', paddingTop: 50 }}>
            <Typography variant="p" color="initial" paddingLeft={5}>How many enrolled users as per courses</Typography>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, 'dataMax + 1']} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="enrollCount" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BarChartComponent;

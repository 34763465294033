import React from 'react'
import { FaEye } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import './CourseCard.css'
import { Button } from '@mui/material'
import ComingSoon from '../images/imgEdu/coming-soon.jpg'


const CourseCard = ({ item }) => {

    return (
        <div className="courseBox">

            <img src={item.thumbnails?.url ? item.thumbnails?.url : ComingSoon} alt="" className='thumbnail' />
            <div className="courseItem">
                <h4 className='headTag'>{item?.name}</h4>
                <p className='ellipsis'> {item?.title}</p>

                <div className='priceBox'>
                    {
                        item?.price === 0 ? 
                        <h4 className='freetag'>Free</h4>
                        : 
                        <>
                         <h4>₹{item?.price - ((item?.price * item?.discount)/100) }</h4>
                         <p>₹{item?.price} </p>
                        </>
                    }

                </div>

                <>
                    {
                        item?.price !== 0 ?
                        <p className='off'>{item?.discount}% off</p>
                        : <span className='offGap'> </span>


                    }
                </>


            </div>

            <div className="actionBox moreCourseBtn ">
                <div className="dateBox">
                    {/* <p>Create : {item?.createAt}</p> */}
                </div>
                <Link to={`/dashboard/course/u/${item._id}`} className=''>
                    <Button size='large' fullWidth variant='contained'>More</Button>
                </Link>
            </div>

        </div>
    )
}

export default CourseCard
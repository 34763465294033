import {


    CLEAR_ERROR,

    GET_PAYMENT_REQUEST,
    GET_PAYMENT_SUCCESS,
    GET_PAYMENT_FAIL,

    GET_ALL_PAYMENTS_REQUEST,
    GET_ALL_PAYMENTS_SUCCESS,
    GET_ALL_PAYMENTS_FAIL,
} from '../constants/paymentsConstant'



export const getPayments = (state = { data: {} }, action) => {
    switch (action.type) {
        case GET_PAYMENT_REQUEST:
        case GET_ALL_PAYMENTS_REQUEST:
            return {
                loading: true,
            };

        case GET_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                payment: action.payload.payment,
            };
        case GET_ALL_PAYMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                payments: action.payload.payments,
            };


        case GET_PAYMENT_FAIL:
        case GET_ALL_PAYMENTS_FAIL:

            return {
                ...state,
                loading: false,
                payments: null,
                payment: null,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

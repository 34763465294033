import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './PaymentPage.css'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { clearError, getAllPayments } from '../../redux/actions/paymentsAction'
import EmptyCard from '../../component/EmptyCard';

const PaymentsPage = () => {
    const dispatch = useDispatch();

    const { loading, payments, error } = useSelector((state) => state.getPayments)
    const { user } = useSelector((state) => state.users)

    useEffect(() => {
        if (error) {
            toast.error(error)
            clearError()
        }
    }, [error])

    useEffect(() => {
        if (user) dispatch(getAllPayments(user?._id))
    }, [user])
    return (
        <div>
            <Typography variant="h5" color="initial" textAlign={'center'}>Payment Statements</Typography>
            {
                loading ? 'Please wait, Loading... ' : 
                <>
                {
                    payments?.length === 0 ? <EmptyCard title={'No Transaction'} /> :
                    <Stack spacing={3} padding={5} alignItems={'center'} className='paidBox'>
                        {
                            payments?.map(item => (
                                <>
                                    {item?.payment_status === 'Successful' &&
                                        < Link to={`/paymentsuccess/${item._id}`} key={item._id} className='itemSuccess itemPaid' >
                                            <div>
                                                <Typography variant="p" color="initial">{item.order_id}</Typography>
                                                <Typography variant="h6" color="green">{item.payment_status}</Typography>
                                            </div>
                                            <div>
                                                <Typography variant="h6" color="rgb(58, 58, 58)"> {item.amount && `₹  ${item.amount}` }</Typography>
                                            </div>
                                        </Link>
                                    }
                                    {item?.payment_status === 'Pending' &&
                                        <Link to={`/paymentsuccess/${item._id}`}  key={item._id} className='itemPending itemPaid'>
                                            <div>
                                                <Typography variant="p" color="initial">{item.order_id}</Typography>
                                                <Typography variant="h6" color="rgb(241, 203, 50)">{item.payment_status}</Typography>
                                            </div>
                                            <div>
                                                <Typography variant="h6" color="initial"> {item.amount && `₹  ${item.amount}` }</Typography>
                                            </div>
                                        </Link>
                                    }
                                    {item?.payment_status === 'Failed' &&
                                        <Link to={`/paymentsuccess/${item._id}`}  key={item._id} className='itemFailed itemPaid'>
                                            <div>
                                                <Typography variant="p" color="initial">{item.order_id}</Typography>
                                                <Typography variant="h6" color="green">{item.payment_status}</Typography>
                                            </div>
                                            <div>
                                                <Typography variant="h6" color="initial">₹ {item.amount && `₹  ${item.amount}` }</Typography>
                                            </div>
                                        </Link>
                                    }
                                </>

                            ))
                        }
                    </Stack>
            }
                </>

            }
        </div >
    )
}

export default PaymentsPage
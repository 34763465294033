import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, CircularProgress, Box, Button, Typography, FormControl, FormLabel, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';


import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
// components
import { clearError, firstLogin, loadUser, login } from '../../redux/actions/userAction';

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    borderRadius: '0.4rem',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function LoginForm({ setUser }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [phone, setPhone] = useState(null);
    const [name, setName] = useState(null)

    const { loading, user:me, error, isAuthenticated } = useSelector((state) => state.users)
    const { user } = useSelector((state) => state.email)


    useEffect(() => {

        if (error) {
            toast.error(error)
            dispatch(clearError())
        }
        if(isAuthenticated){
            navigate('/dashboard/app')
    
        }
        // setemail(user?.email)
        setUser(me)

    }, [error, navigate, isAuthenticated, dispatch, me])

    const loginSubmitHandler = (e) => {
        e.preventDefault()
        dispatch(firstLogin({email:user?.email ,phone,name }))
    }






    return (
        <>
            <form action="" onSubmit={loginSubmitHandler}>
                <Stack spacing={3}>

                    <FormControl>
                        <TextField name="email"
                            value={name}
                            // disabled
                            onChange={e => setName(e.target.value)}
                            required label="Name" />
                    </FormControl>

                    <FormControl>
                        <TextField name="email"
                            value={phone}
                            // disabled
                            onChange={e => setPhone(e.target.value)}
                            required label="Phone" />
                    </FormControl>
                    
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    {/* <Checkbox name="remember"label="Remember me" />  */}
                    {/* <Link variant="subtitle2" underline="hover" onClick={handleOpen}>
            Forgot password?
          </Link> */}
                </Stack>

                {loading ?

                    (<LoadingButton fullWidth size="large" variant="text" >
                        <CircularProgress color='success' />
                    </LoadingButton>)
                    :

                    (<LoadingButton fullWidth size="large" type="submit" variant="contained" >
                        Submit
                    </LoadingButton>)

                }
            </form>



        </>
    );
}
